/* eslint-disable  @typescript-eslint/no-non-null-assertion ,react/no-unstable-nested-components */
import Switch from "react-switch";
import Header from "../../../components/header/header";
import styles from "./cardControlsStyles.module.scss";
import Slider from "react-slider";
import cardIcon from "../../../assets/svgs/cardIcon.svg";
import sliderStar from "../../../assets/svgs/sliderstar.svg";
import { useEffect, useRef, useState } from "react";
import { Button2 } from "../../../components";
import {
  default as CurrencyFormat,
  default as NumberFormat
} from "react-currency-format";
import { toast } from "../../../components/toast/toast";
import { CORRELATION_ID_HEADER, INTEGER_REGEX } from "../../../utils/constants";
import { ToastType } from "../../../components/toast/toastTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedAccount,
  getSelectedCard,
  setCustomerBlockCodes,
  updateSelectedCard
} from "../../../features/user/userSlice";
import { useNavigate } from "react-router-dom";
import ApiIds from "../../../auth/ApiIds";
import { authenticateApi } from "../../../auth/auth";
import { AuthStatus } from "../../../features/auth/authSliceTypes";
import logger from "../../../utils/logger";
import { HOME_ROUTE, MANAGE_CARD_ROUTE } from "../../../routes/ScreenRoutes";
import CardsApi from "../../../apis/cardsApi/CardsApi";
import { CardControl } from "../../../apis/cardsApi/cardsApiTypes";
import Loader from "../../../components/loader/loader";
import cardIconDisable from "../../../assets/svgs/cardIconDisable.svg";
import {
  getCustomerBlockCodeStatus,
  isBlockCodeActionEnabled
} from "../../../data/blockCodes/BlockCodeUtil";
import { Action } from "../../../data/blockCodes/BlockCodeTypes";
import AppApi from "../../../apis/appApi/AppApi";
import { RootState } from "../../../app/store";
import { captureEvent } from "../../../utils/functions";
import { EVENT_NAME } from "../../../apis/appApi/appApiTypes";

const css = `

    .slider{
  z-index: 100;
  border-radius: 10px;
  height: 2px;
}

.sliderTrack{
  position: relative;
  background:#333333 ;
  height: 2px;
}

.sliderTrack-0{
    background: #F4B348 ;
    
    }
.sliderTrack-1{
  background: #333333 ;
  height: 2px;

}
.reactSwitch{
  height: 20px;
  width: 40px;
}
`;

interface InitConfig {
  domesticOnline?: CardControl;
  domesticOffline?: CardControl;
  domesticContactless?: CardControl;
  domesticCash?: CardControl;
  domesticMoto?: CardControl;
  internationalOnline?: CardControl;
  internationalOffline?: CardControl;
  internationalContactless?: CardControl;
  internationalCash?: CardControl;
  internationalMoto?: CardControl;
}

interface InitCardSettings {
  isCardLocked?: boolean;
  domestic: {
    online: {
      isEnabled?: boolean;
      dailyLimit: number;
    };
    offline: {
      isEnabled?: boolean;
      dailyLimit: number;
    };
    contactless?: {
      isEnabled?: boolean;
      dailyLimit: number;
    };
    cash?: {
      isEnabled?: boolean;
      dailyLimit: number;
    };
    moto?: {
      isEnabled?: boolean;
      dailyLimit: number;
    };
  };
  international?: {
    online?: {
      isEnabled?: boolean;
      dailyLimit: number;
    };
    offline?: {
      isEnabled?: boolean;
      dailyLimit: number;
    };
    contactless?: {
      isEnabled?: boolean;
      dailyLimit: number;
    };
    cash?: {
      isEnabled?: boolean;
      dailyLimit: number;
    };
    moto?: {
      isEnabled?: boolean;
      dailyLimit: number;
    };
  };
}

interface Channel {
  isEnabled?: boolean;
  dailyLimit?: number;
  label: string;
}

interface Location {
  online?: Channel;
  offline?: Channel;
  contactless?: Channel;
  cash?: Channel;
  moto?: Channel;
}

interface CardSettings {
  domestic?: Location;
  international?: Location;
}

type LocationKey = "domestic" | "international";

type PosEntryMode = "online" | "offline" | "contactless" | "cash" | "moto";

const CardControls = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const account = useSelector(getSelectedAccount)!;
  const card = useSelector(getSelectedCard)!;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isDomesticSelected, setIsDomesticSelected] = useState<boolean>(true);
  const [isCardLocked, setIsCardLocked] = useState(false);
  const [cardSettings, setCardSettings] = useState<CardSettings>();
  const [showMore, setShowMore] = useState<boolean>(false);
  const [oldCardControls, setOldCardControls] = useState<CardControl[]>([])

  let initCardSettings = useRef<InitCardSettings>();

  const approvedCreditLimit = account?.approvedCreditLimit;
  const approvedCashLimit = account?.approvedCashLimit;
  const isAccountActive = account?.status === "ACTIVE";
  const isVirtualCard =
    account?.cards[0]?.isVirtualCardActivated &&
    (account?.cards[0]?.cardType === "Virtual" ||
      !account?.cards[0]?.isPhysicalCardActivated);

  // block code checks
  const isDomesticEnabled = Boolean(
    isBlockCodeActionEnabled(Action.ENABLE_DOMESTIC_CARD_CONTROLS)
  );
  const isInternationalEnabled = Boolean(
    isBlockCodeActionEnabled(Action.ENABLE_INTERNATIONAL_CARD_CONTROLS)
  );
  const isLockCardEnabled: boolean = isBlockCodeActionEnabled(Action.LOCK_CARD);
  const isUnlockCardEnabled: boolean = isBlockCodeActionEnabled(
    Action.UNLOCK_CARD
  );

  // get data
  useEffect(() => {
    (async () => {
      try {
        const getCardAuthResult = await authenticateApi({
          apiId: ApiIds.FETCH_CARD,
          actionText: "to get card details"
        });

        if (getCardAuthResult.status === AuthStatus.FAILURE) {
          logger.error({
            accountId: account.id,
            error: getCardAuthResult.error,
            message: `An error occurred while authenticating get card details api`
          });
          toast(ToastType.ERROR, getCardAuthResult.message);
          navigate(MANAGE_CARD_ROUTE);
          return;
        } else if (getCardAuthResult.status === AuthStatus.CANCELLED) {
          navigate(MANAGE_CARD_ROUTE);
          return;
        }

        const getCardResponse = await CardsApi.getCard(
          account?.cards[0]?.id,
          getCardAuthResult.apiToken
        );

        if (getCardResponse.status !== 200) {
          logger.error({
            correlationId: getCardResponse.headers[CORRELATION_ID_HEADER],
            accountId: account.id,
            responseData: getCardResponse.data,
            message: `An error occurred while getting card details`
          });
          toast(
            ToastType.ERROR,
            "We are unable to process your request. Please try again"
          );
          navigate(MANAGE_CARD_ROUTE);
          return;
        }

        const getCardControlsAuthResult = await authenticateApi({
          apiId: ApiIds.FETCH_CARD_CONTROLS,
          actionText: "to view card controls"
        });

        if (getCardControlsAuthResult.status === AuthStatus.FAILURE) {
          logger.error({
            accountId: account.id,
            error: getCardControlsAuthResult.error,
            message: `An error occurred while getting card controls`
          });
          toast(ToastType.ERROR, getCardControlsAuthResult.message);
          navigate(MANAGE_CARD_ROUTE);
          return;
        } else if (getCardControlsAuthResult.status === AuthStatus.CANCELLED) {
          navigate(MANAGE_CARD_ROUTE);
          return;
        }

        const getCardControlsResponse = await CardsApi.getCardControls(
          account?.cards[0]?.id,
          getCardControlsAuthResult.apiToken
        );

        if (getCardControlsResponse.status !== 200) {
          logger.error({
            correlationId:
              getCardControlsResponse.headers[CORRELATION_ID_HEADER],
            accountId: account.id,
            responseData: getCardControlsResponse.data,
            message: `An error occurred while getting Card controls`
          });
          toast(
            ToastType.ERROR,
            "Can’t display transaction preferences/limits now. Please try again"
          );
          navigate(MANAGE_CARD_ROUTE);
          return;
        }

        setOldCardControls(getCardControlsResponse.data)
        const isCardLocked = getCardResponse.data?.isLocked;
        const cardControls = getCardControlsResponse.data;

        // get init config from api response
        let initConfig: InitConfig = {
          domesticOnline: cardControls?.filter((item) => {
            return (
              item.labelsMap.SOVEREIGNTY_INDICATOR === "DOMESTIC" &&
              item.labelsMap.MCC === "RETAIL" &&
              item.labelsMap.POS_ENTRY_MODE === "ECOMMERCE"
            );
          })[0],
          domesticOffline: cardControls?.filter((item) => {
            return (
              item.labelsMap.SOVEREIGNTY_INDICATOR === "DOMESTIC" &&
              item.labelsMap.MCC === "RETAIL" &&
              item.labelsMap.POS_ENTRY_MODE === "CONTACT"
            );
          })[0],
          domesticContactless: cardControls?.filter((item) => {
            return (
              item.labelsMap.SOVEREIGNTY_INDICATOR === "DOMESTIC" &&
              item.labelsMap.MCC === "RETAIL" &&
              item.labelsMap.POS_ENTRY_MODE === "CONTACTLESS"
            );
          })[0],
          domesticMoto: cardControls?.filter((item) => {
            return (
              item.labelsMap.SOVEREIGNTY_INDICATOR === "DOMESTIC" &&
              item.labelsMap.MCC === "RETAIL" &&
              item.labelsMap.POS_ENTRY_MODE === "MOTO"
            );
          })[0],
          domesticCash: cardControls?.filter((item) => {
            return (
              item.labelsMap.SOVEREIGNTY_INDICATOR === "DOMESTIC" &&
              item.labelsMap.MCC === "CASH" &&
              item.labelsMap.POS_ENTRY_MODE === null
            );
          })[0],
          internationalOnline: cardControls?.filter((item) => {
            return (
              item.labelsMap.SOVEREIGNTY_INDICATOR === "INTERNATIONAL" &&
              item.labelsMap.MCC === "RETAIL" &&
              item.labelsMap.POS_ENTRY_MODE === "ECOMMERCE"
            );
          })[0],
          internationalOffline: cardControls?.filter((item) => {
            return (
              item.labelsMap.SOVEREIGNTY_INDICATOR === "INTERNATIONAL" &&
              item.labelsMap.MCC === "RETAIL" &&
              item.labelsMap.POS_ENTRY_MODE === "CONTACT"
            );
          })[0],
          internationalContactless: cardControls?.filter((item) => {
            return (
              item.labelsMap.SOVEREIGNTY_INDICATOR === "INTERNATIONAL" &&
              item.labelsMap.MCC === "RETAIL" &&
              item.labelsMap.POS_ENTRY_MODE === "CONTACTLESS"
            );
          })[0],
          internationalMoto: cardControls?.filter((item) => {
            return (
              item.labelsMap.SOVEREIGNTY_INDICATOR === "INTERNATIONAL" &&
              item.labelsMap.MCC === "RETAIL" &&
              item.labelsMap.POS_ENTRY_MODE === "MOTO"
            );
          })[0],
          internationalCash: cardControls?.filter((item) => {
            return (
              item.labelsMap.SOVEREIGNTY_INDICATOR === "INTERNATIONAL" &&
              item.labelsMap.MCC === "CASH" &&
              item.labelsMap.POS_ENTRY_MODE === null
            );
          })[0]
        };

        // save init settings
        initCardSettings.current = {
          isCardLocked: isCardLocked,

          domestic: {
            online: {
              isEnabled: initConfig?.domesticOnline?.isEnabled,
              dailyLimit: initConfig?.domesticOnline?.dailyTxnLimit?.limit || 0
            },
            offline: {
              isEnabled: initConfig?.domesticOffline?.isEnabled,
              dailyLimit: initConfig?.domesticOffline?.dailyTxnLimit?.limit || 0
            },
            contactless: {
              isEnabled: initConfig?.domesticContactless?.isEnabled,
              dailyLimit:
                initConfig?.domesticContactless?.dailyTxnLimit?.limit || 0
            },
            cash: {
              isEnabled: initConfig?.domesticCash?.isEnabled,
              dailyLimit: initConfig?.domesticCash?.dailyTxnLimit?.limit || 0
            },
            moto: {
              isEnabled: initConfig?.domesticMoto?.isEnabled,
              dailyLimit: initConfig?.domesticMoto?.dailyTxnLimit?.limit || 0
            }
          },

          international: {
            online: {
              isEnabled: initConfig.internationalOnline?.isEnabled,
              dailyLimit:
                initConfig.internationalOnline?.dailyTxnLimit?.limit || 0
            },
            offline: {
              isEnabled: initConfig.internationalOffline?.isEnabled,
              dailyLimit:
                initConfig.internationalOffline?.dailyTxnLimit?.limit || 0
            },
            contactless: {
              isEnabled: initConfig.internationalContactless?.isEnabled,
              dailyLimit:
                initConfig.internationalContactless?.dailyTxnLimit?.limit || 0
            },
            cash: {
              isEnabled: initConfig.internationalCash?.isEnabled,
              dailyLimit:
                initConfig.internationalCash?.dailyTxnLimit?.limit || 0
            },
            moto: {
              isEnabled: initConfig.internationalMoto?.isEnabled,
              dailyLimit:
                initConfig.internationalMoto?.dailyTxnLimit?.limit || 0
            }
          }
        };

        // set state
        setIsCardLocked(isCardLocked);

        setCardSettings(() => {
          return {
            domestic: {
              online: {
                label: "Online Transaction",
                ...initCardSettings?.current?.domestic?.online
              },
              offline: {
                label: "POS",
                ...initCardSettings?.current?.domestic?.offline
              },
              contactless: {
                label: "Contactless",
                ...initCardSettings?.current?.domestic?.contactless
              },
              moto: {
                label: "Mail Order/Telephone Order",
                ...initCardSettings?.current?.domestic?.moto
              },
              cash: {
                label: "Cash Withdrawal",
                ...initCardSettings?.current?.domestic?.cash
              }
            },
            international: {
              online: {
                label: "Online Transaction",
                ...initCardSettings?.current?.international?.online
              },
              offline: {
                label: "POS",
                ...initCardSettings?.current?.international?.offline
              },
              contactless: {
                label: "Contactless",
                ...initCardSettings?.current?.international?.contactless
              },
              moto: {
                label: "Mail Order/Telephone Order",
                ...initCardSettings?.current?.international?.moto
              },
              cash: {
                label: "Cash Withdrawal",
                ...initCardSettings?.current?.international?.cash
              }
            }
          };
        });
      } catch (error: any) {
        logger.error({
          message: `An exception occurred while getting card controls`,
          error: error.message,
          accountId: account.id,
          stackTrace: error.stack
        });
        toast(
          ToastType.ERROR,
          "We are unable to process your request. Please try again later"
        );
        navigate(HOME_ROUTE);
      }

      setIsLoading(false);
    })();
  }, []);

  const getMaxLimit = (channel: Channel) => {
    return channel.label === "Cash Withdrawal"
      ? approvedCashLimit
      : approvedCreditLimit;
  };

  const returnNumberOrUndefined = (input: any) => {
    if (typeof input === "number" && !isNaN(input)) {
      return input;
    } else {
      return undefined;
    }
  };

  const getCurrentCardControls = (changedCardSettings: any) => {
    const channels = [];
    if (
      (changedCardSettings?.domestic?.moto?.isEnabled ??
        initCardSettings.current?.domestic?.moto?.isEnabled) !==
        initCardSettings.current?.domestic?.moto?.isEnabled ||
      Number(changedCardSettings?.domestic?.moto?.dailyLimit)
    ) {
      channels.push({
        txnTags: {
          location: "DOMESTIC",
          txnType: "RETAIL",
          txnChannel: "MOTO"
        },
        isEnabled: changedCardSettings?.domestic?.moto.isEnabled,
        dailyTxnLimit: returnNumberOrUndefined(
          Number(changedCardSettings?.domestic?.moto?.dailyLimit)
        )
      });
    }

    if (
      (changedCardSettings?.international?.moto?.isEnabled ??
        initCardSettings.current?.international?.moto?.isEnabled) !==
        initCardSettings.current?.international?.moto?.isEnabled ||
      Number(changedCardSettings?.domestic?.moto?.dailyLimit)
    ) {
      channels.push({
        txnTags: {
          location: "INTERNATIONAL",
          txnType: "RETAIL",
          txnChannel: "MOTO"
        },
        isEnabled: changedCardSettings?.international?.moto.isEnabled,
        dailyTxnLimit: returnNumberOrUndefined(
          Number(changedCardSettings?.international?.moto?.dailyLimit)
        )
      });
    }

    if (
      (changedCardSettings?.domestic?.online?.isEnabled ??
        initCardSettings.current?.domestic?.online?.isEnabled) !==
        initCardSettings.current?.domestic?.online?.isEnabled ||
      Number(changedCardSettings?.domestic?.online?.dailyLimit)
    ) {
      channels.push({
        txnTags: {
          location: "DOMESTIC",
          txnType: "RETAIL",
          txnChannel: "ECOMMERCE"
        },
        isEnabled: changedCardSettings?.domestic?.online?.isEnabled,
        dailyTxnLimit: returnNumberOrUndefined(
          Number(changedCardSettings?.domestic?.online?.dailyLimit)
        )
      });
    }

    if (
      (changedCardSettings?.international?.online?.isEnabled ??
        initCardSettings.current?.international?.online?.isEnabled) !==
        initCardSettings.current?.international?.online?.isEnabled ||
      Number(changedCardSettings?.domestic?.online?.dailyLimit)
    ) {
      channels.push({
        txnTags: {
          location: "INTERNATIONAL",
          txnType: "RETAIL",
          txnChannel: "ECOMMERCE"
        },
        isEnabled: changedCardSettings?.international?.online?.isEnabled,
        dailyTxnLimit: returnNumberOrUndefined(
          Number(changedCardSettings?.domestic?.online?.dailyLimit)
        )
      });
    }

    if (
      (changedCardSettings?.domestic?.offline?.isEnabled ??
        initCardSettings.current?.domestic?.offline?.isEnabled) !==
        initCardSettings.current?.domestic?.offline?.isEnabled ||
      Number(changedCardSettings?.domestic?.offline?.dailyLimit)
    ) {
      channels.push({
        txnTags: {
          location: "DOMESTIC",
          txnType: "RETAIL",
          txnChannel: "CONTACT"
        },
        isEnabled: changedCardSettings?.domestic?.offline?.isEnabled,
        dailyTxnLimit: returnNumberOrUndefined(
          Number(changedCardSettings?.domestic?.offline?.dailyLimit)
        )
      });
    }

    // need to set same config as contact for magnetic
    if (
      (changedCardSettings?.domestic?.offline?.isEnabled ??
        initCardSettings.current?.domestic?.offline?.isEnabled) !==
        initCardSettings.current?.domestic?.offline?.isEnabled ||
      Number(changedCardSettings?.domestic?.offline?.dailyLimit)
    ) {
      channels.push({
        txnTags: {
          location: "DOMESTIC",
          txnType: "RETAIL",
          txnChannel: "MAGNETIC"
        },
        isEnabled: changedCardSettings?.domestic?.offline?.isEnabled,
        dailyTxnLimit: returnNumberOrUndefined(
          Number(changedCardSettings?.domestic?.offline?.dailyLimit)
        )
      });
    }

    if (
      (changedCardSettings?.international?.offline?.isEnabled ??
        initCardSettings.current?.international?.offline?.isEnabled) !==
        initCardSettings.current?.international?.offline?.isEnabled ||
      Number(changedCardSettings?.domestic?.offline?.dailyLimit)
    ) {
      channels.push({
        txnTags: {
          location: "INTERNATIONAL",
          txnType: "RETAIL",
          txnChannel: "CONTACT"
        },
        isEnabled: changedCardSettings?.international?.offline?.isEnabled,
        dailyTxnLimit: returnNumberOrUndefined(
          Number(changedCardSettings?.domestic?.offline?.dailyLimit)
        )
      });
    }

    if (
      (changedCardSettings?.international?.offline?.isEnabled ??
        initCardSettings.current?.international?.offline?.isEnabled) !==
        initCardSettings.current?.international?.offline?.isEnabled ||
      Number(changedCardSettings?.domestic?.offline?.dailyLimit)
    ) {
      channels.push({
        txnTags: {
          location: "INTERNATIONAL",
          txnType: "RETAIL",
          txnChannel: "MAGNETIC"
        },
        isEnabled: changedCardSettings?.international?.offline?.isEnabled,
        dailyTxnLimit: returnNumberOrUndefined(
          Number(changedCardSettings?.domestic?.offline?.dailyLimit)
        )
      });
    }

    if (
      (changedCardSettings?.domestic?.contactless?.isEnabled ??
        initCardSettings.current?.domestic?.contactless?.isEnabled) !==
        initCardSettings.current?.domestic?.contactless?.isEnabled ||
      Number(changedCardSettings?.domestic?.contactless?.dailyLimit)
    ) {
      channels.push({
        txnTags: {
          location: "DOMESTIC",
          txnType: "RETAIL",
          txnChannel: "CONTACTLESS"
        },
        isEnabled: changedCardSettings?.domestic?.contactless?.isEnabled,
        dailyTxnLimit: returnNumberOrUndefined(
          Number(changedCardSettings?.domestic?.contactless?.dailyLimit)
        )
      });
    }

    if (
      (changedCardSettings?.international?.contactless?.isEnabled ??
        initCardSettings.current?.international?.contactless?.isEnabled) !==
        initCardSettings.current?.international?.contactless?.isEnabled ||
      Number(changedCardSettings?.domestic?.contactless?.dailyLimit)
    ) {
      channels.push({
        txnTags: {
          location: "INTERNATIONAL",
          txnType: "RETAIL",
          txnChannel: "CONTACTLESS"
        },
        isEnabled: changedCardSettings?.international?.contactless?.isEnabled,
        dailyTxnLimit: returnNumberOrUndefined(
          Number(changedCardSettings?.domestic?.contactless?.dailyLimit)
        )
      });
    }

    if (
      (changedCardSettings?.domestic?.cash?.isEnabled ??
        initCardSettings.current?.domestic?.cash?.isEnabled) !==
        initCardSettings.current?.domestic?.cash?.isEnabled ||
      Number(changedCardSettings?.domestic?.cash?.dailyLimit)
    ) {
      channels.push({
        txnTags: {
          location: "DOMESTIC",
          txnType: "CASH"
        },
        isEnabled: changedCardSettings?.domestic?.cash?.isEnabled,
        dailyTxnLimit: returnNumberOrUndefined(
          Number(changedCardSettings?.domestic?.cash?.dailyLimit)
        )
      });
    }

    if (
      (changedCardSettings?.international?.cash?.isEnabled ??
        initCardSettings.current?.international?.cash?.isEnabled) !==
        initCardSettings.current?.international?.cash?.isEnabled ||
      Number(changedCardSettings?.domestic?.cash?.dailyLimit)
    ) {
      channels.push({
        txnTags: {
          location: "INTERNATIONAL",
          txnType: "CASH"
        },
        isEnabled: changedCardSettings?.international?.cash?.isEnabled,
        dailyTxnLimit: returnNumberOrUndefined(
          Number(changedCardSettings?.domestic?.cash?.dailyLimit)
        )
      });
    }

    return channels;
  };


  const haveCardControlsChanged = () => {
    return (
      // check domestic
      initCardSettings?.current?.domestic?.online?.isEnabled !==
        cardSettings?.domestic?.online?.isEnabled ||
      initCardSettings?.current?.domestic?.online?.dailyLimit !==
        Number(cardSettings?.domestic?.online?.dailyLimit) ||
      initCardSettings?.current?.domestic?.offline?.isEnabled !==
        cardSettings?.domestic?.offline?.isEnabled ||
      initCardSettings?.current?.domestic?.offline?.dailyLimit !==
        Number(cardSettings?.domestic?.offline?.dailyLimit) ||
      initCardSettings?.current?.domestic?.contactless?.isEnabled !==
        cardSettings?.domestic?.contactless?.isEnabled ||
      initCardSettings?.current?.domestic?.contactless?.dailyLimit !==
        Number(cardSettings?.domestic?.contactless?.dailyLimit) ||
      initCardSettings?.current?.domestic?.moto?.isEnabled !==
        cardSettings?.domestic?.moto?.isEnabled ||
      initCardSettings?.current?.domestic?.moto?.dailyLimit !==
        Number(cardSettings?.domestic?.moto?.dailyLimit) ||
      initCardSettings?.current?.domestic?.cash?.isEnabled !==
        cardSettings?.domestic?.cash?.isEnabled ||
      initCardSettings?.current?.domestic?.cash?.dailyLimit !==
        Number(cardSettings?.domestic?.cash?.dailyLimit) ||
      // check international
      initCardSettings?.current?.international?.online?.isEnabled !==
        cardSettings?.international?.online?.isEnabled ||
      initCardSettings?.current?.international?.online?.dailyLimit !==
        Number(cardSettings?.international?.online?.dailyLimit) ||
      initCardSettings?.current?.international?.offline?.isEnabled !==
        cardSettings?.international?.offline?.isEnabled ||
      initCardSettings?.current?.international?.offline?.dailyLimit !==
        Number(cardSettings?.international?.offline?.dailyLimit) ||
      initCardSettings?.current?.international?.contactless?.isEnabled !==
        cardSettings?.international?.contactless?.isEnabled ||
      initCardSettings?.current?.international?.contactless?.dailyLimit !==
        Number(cardSettings?.international?.contactless?.dailyLimit) ||
      initCardSettings?.current?.international?.moto?.isEnabled !==
        cardSettings?.international?.moto?.isEnabled ||
      initCardSettings?.current?.international?.moto?.dailyLimit !==
        Number(cardSettings?.international?.moto?.dailyLimit) ||
      initCardSettings?.current?.international?.cash?.isEnabled !==
        cardSettings?.international?.cash?.isEnabled ||
      initCardSettings?.current?.international?.cash?.dailyLimit !==
        Number(cardSettings?.international?.cash?.dailyLimit)
    );
  };

  const areCardControlsValid = () => {
    // not validating anything if user is going to lock the card
    if (isCardLocked) return true;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (let [key, channel] of Object.entries(cardSettings!.domestic!)) {
      // only validate if channel is enabled
      if (channel?.isEnabled) {
        if (Number(channel?.dailyLimit) < 100) {
          toast(
            ToastType.ERROR,
            `The domestic ${channel.label} limit must be greater than 100`
          );
          return false;
        }

        if (Number(channel?.dailyLimit) % 100 !== 0) {
          toast(
            ToastType.ERROR,
            `The domestic ${channel.label} limit must be in multiples of 100`
          );
          return false;
        }
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (let [key, channel] of Object.entries(cardSettings!.international!)) {
      if (channel?.isEnabled) {
        if (Number(channel?.dailyLimit) < 100) {
          toast(
            ToastType.ERROR,
            `The international ${channel.label} limit must be greater than 100`
          );
          return false;
        }

        if (Number(channel?.dailyLimit) % 100 !== 0) {
          toast(
            ToastType.ERROR,
            `The international ${channel.label} limit must be in multiples of 100`
          );
          return false;
        }
      }
    }


    return true;
  };

  const findDifferentValues = (obj1: any, obj2: any) => {
    const result: any = {};

    for (const key in obj1) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
          // Recursively compare nested objects
          const nestedDiff = findDifferentValues(obj1[key], obj2[key]);
          if (Object.keys(nestedDiff).length > 0) {
            result[key] = nestedDiff;
          }
        } else if (obj1[key] !== obj2[key]) {
          // Check if values are different
          result[key] = obj2[key];
        }
      }
    }

    return result;
  };

  const handleSubmitClick = async () => {
    const changedValues = findDifferentValues(
      initCardSettings.current,
      cardSettings
    );

    if (!haveCardControlsChanged()) {
      toast(ToastType.WARNING, "Please change your control limits");
      return;
    }

    if (!areCardControlsValid()) return;

    setIsSubmitLoading(true);

    try {
      // always call update card controls api as auth will be configured using this
      const cardControlsAuthResult = await authenticateApi({
        apiId: ApiIds.UPDATE_CARD_CONTROLS,
        actionText: "to update card controls"
      });

      if (cardControlsAuthResult.status === AuthStatus.FAILURE) {
        logger.error({
          accountId: account.id,
          error: cardControlsAuthResult.error,
          message: `An error occurred while ${
            isCardLocked ? "locking" : "unlocking"
          } card`
        });
        toast(ToastType.ERROR, cardControlsAuthResult.message);
        setIsSubmitLoading(false);
        return;
      } else if (cardControlsAuthResult.status === AuthStatus.CANCELLED) {
        setIsSubmitLoading(false);
        return;
      }

      const currentCardControls = getCurrentCardControls(changedValues);

      const cardControlsResponse = await CardsApi.updateCardControls(
        account.cards[0]?.id,
        cardControlsAuthResult.apiToken,
        {
          updateCardControls: currentCardControls,
          oldCardControls: oldCardControls
        }
      );

      if (cardControlsResponse.status !== 200) {
        logger.error({
          correlationId: cardControlsResponse.headers[CORRELATION_ID_HEADER],
          cardId: card.id,
          responseData: cardControlsResponse.data,
          message: `An error occurred while updating card controls`
        });
        toast(
          ToastType.ERROR,
          "Update of transaction preferences/limits has failed. Please try again"
        );
        setIsSubmitLoading(false);
        return;
      }

      setOldCardControls(cardControlsResponse.data.cardControl)

      if (!haveCardControlsChanged()) {
        isCardLocked
          ? toast(ToastType.SUCCESS, "Your card is temporarily blocked")
          : toast(ToastType.SUCCESS, "Your Card is unblocked");
      } else {
        toast(
          ToastType.SUCCESS,
          "Your Transaction Usage Preferences and Limits are saved"
        );
      }

      captureEvent({eventName:EVENT_NAME.CARD_CONTROLS_CHANGED});

      navigate(-1);
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while updating card controls`,
        error: error.message,
        accountId: account.id,
        stackTrace: error.stack
      });
      toast(
        ToastType.ERROR,
        "Update of transaction preferences/limits has failed. Please try again"
      );
    }

    setIsSubmitLoading(false);
  };

  const GetSettings = (locationTemp: any, controlEnabled: boolean) => {
    let location: LocationKey = locationTemp;
    return (
      <>
        {cardSettings
          ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
            Object.entries(cardSettings![location]!).map((item, index) => {
              const posEntryMode = item[0] as PosEntryMode;
              return (
                <div className={styles.card} key={index}>
                  <style>{css}</style>
                  <div className={styles.cardHeader}>
                    <div className={styles.cardIconContainer}>
                      <img
                        src={
                          controlEnabled && !isCardLocked && item[1].isEnabled
                            ? cardIcon
                            : cardIconDisable
                        }
                        alt="icon"
                        className={styles.cardIcon1}
                      />
                    </div>
                    {
                      <div className={styles.cardHeaderText}>
                        {item[1].label}
                      </div>
                    }
                    <Switch
                      disabled={
                        isCardLocked ||
                        (isAccountActive &&
                          card &&
                          !card.isHotlisted &&
                          isVirtualCard &&
                          item[1].label !== "Online Transaction") ||
                        !controlEnabled
                      }
                      checked={
                        controlEnabled && !isCardLocked && item[1].isEnabled
                      }
                      onChange={() => {
                        !isCardLocked &&
                          setCardSettings((currCardSetting) => ({
                            ...currCardSetting,
                            [location]: {
                              ...currCardSetting![location],
                              [posEntryMode]: {
                                ...currCardSetting![location]![posEntryMode],
                                isEnabled:
                                  !currCardSetting![location]![posEntryMode]
                                    ?.isEnabled
                              }
                            }
                          }));
                      }}
                      handleDiameter={18}
                      checkedIcon={false}
                      offColor="#333333"
                      uncheckedIcon={false}
                      onColor="#F4B348"
                      height={22}
                      width={40}
                      className={styles.reactSwitch}
                      offHandleColor="#999999"
                    />
                  </div>
                  {!isCardLocked && item[1].isEnabled && controlEnabled && (
                    <div className={styles.cardContent}>
                      <div className={styles.cardValue}>
                        <CurrencyFormat
                          isAllowed={(values) =>
                            Number(values?.value) <= getMaxLimit(item[1])
                          }
                          allowNegative={false}
                          decimalScale={0}
                          size="md"
                          placeholder="NA"
                          textAlign={"center"}
                          thousandSpacing="2s"
                          thousandSeparator={true}
                          prefix={"₹ "}
                          className={styles.currencyFormat}
                          fontWeight="bold"
                          isReadOnly={
                            !item[1].isEnabled ||
                            (isCardLocked &&
                              item[1].label !== "Online Transaction" &&
                              isVirtualCard) ||
                            !controlEnabled
                          }
                          value={
                            !item[1].isEnabled ||
                            isCardLocked ||
                            !controlEnabled
                              ? "NA"
                              : item[1].dailyLimit
                          }
                          renderText={(value) => (
                            <div className={styles.cardValue}>{value}</div>
                          )}
                          fontSize="18px"
                          variant="underlined"
                          onValueChange={(values) => {
                            let amount = values?.value;

                            if (amount.length > 9) return false;
                            // if (INTEGER_REGEX.test(amount)) {
                            setCardSettings((currCardSetting) => ({
                              ...currCardSetting,
                              ["domestic"]: {
                                ...currCardSetting!["domestic"],
                                [posEntryMode]: {
                                  ...currCardSetting!["domestic"]![
                                    posEntryMode
                                  ],
                                  dailyLimit: Number(amount)
                                }
                              },
                              ["international"]: {
                                ...currCardSetting!["international"],
                                [posEntryMode]: {
                                  ...currCardSetting!["international"]![
                                    posEntryMode
                                  ],
                                  dailyLimit: Number(amount)
                                }
                              }
                            }));
                          }}
                        />
                      </div>
                      <Slider
                        // snapDragDisabled={true}
                        defaultValue={item[1].dailyLimit}
                        disabled={
                          !item[1].isEnabled || isCardLocked || !controlEnabled
                        }
                        min={100}
                        max={getMaxLimit(item[1])}
                        step={100}
                        value={item[1].dailyLimit}
                        onChange={(amount) => {
                          if (amount.length > 9) return false;
                          if (INTEGER_REGEX.test(amount)) {
                            setCardSettings((currCardSetting) => ({
                              ...currCardSetting,
                              ["domestic"]: {
                                ...currCardSetting!["domestic"],
                                [posEntryMode]: {
                                  ...currCardSetting!["domestic"]![
                                    posEntryMode
                                  ],
                                  dailyLimit: amount
                                }
                              },
                              ["international"]: {
                                ...currCardSetting!["international"],
                                [posEntryMode]: {
                                  ...currCardSetting!["international"]![
                                    posEntryMode
                                  ],
                                  dailyLimit: amount
                                }
                              }
                            }));
                          }
                        }}
                        // onChange={(state) => handleSliderChange(state)}
                        className={styles.slider}
                        thumbClassName={styles.sliderThumb}
                        trackClassName="sliderTrack"
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        renderThumb={(props, state) => (
                          <div
                            className={styles.sliderThumbContainer}
                            {...props}
                          >
                            <img
                              className={styles.sliderThumbImg}
                              src={sliderStar}
                              alt=""
                            />
                          </div>
                        )}
                      />

                      <div className={styles.cardTextContainer}>
                        <div>
                          <div className={styles.cardMinText}>Min</div>
                          <div className={styles.cardMinAmount}>
                            <NumberFormat
                              flex="1"
                              value={"100"}
                              displayType={"text"}
                              thousandSpacing="2s"
                              thousandSeparator={true}
                              prefix={"₹"}
                            />
                          </div>
                        </div>
                        <div>
                          <div className={styles.cardMaxText}>Max</div>
                          <div className={styles.cardMaxAmount}>
                            <NumberFormat
                              flex="1"
                              value={getMaxLimit(item[1])}
                              displayType={"text"}
                              thousandSpacing="2s"
                              thousandSeparator={true}
                              prefix={"₹"}
                            />{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })
          : null}
      </>
    );
  };
  const cardId = useSelector((state: RootState) => state.user.selectedCardId);
  const handleCardLock = async (isCardLocked: boolean) => {
    setIsSubmitLoading(true);
    try {
      const lockCardAuthResult = await authenticateApi({
        apiId: isCardLocked ? ApiIds.LOCK_CARD : ApiIds.UNLOCK_CARD,
        actionText: `to ${isCardLocked ? "lock" : "unlock"} card`
      });
      if (lockCardAuthResult.status === AuthStatus.FAILURE) {
        logger.error({
          accountId: account.id,
          error: lockCardAuthResult.error,
          message: `An error occurred while ${
            isCardLocked ? "locking" : "unlocking"
          } card`
        });
        toast(ToastType.ERROR, lockCardAuthResult.message);
        setIsSubmitLoading(false);
        return;
      } else if (lockCardAuthResult.status === AuthStatus.CANCELLED) {
        setIsSubmitLoading(false);
        return;
      }

      const lockCardResponse = isCardLocked
        ? await CardsApi.lockCard(
            account.cards[0]?.id,
            lockCardAuthResult.apiToken
          )
        : await CardsApi.unlockCard(
            account.cards[0]?.id,
            lockCardAuthResult.apiToken
          );

      if (lockCardResponse.status !== 200) {
        logger.error({
          correlationId: lockCardResponse.headers[CORRELATION_ID_HEADER],
          accountId: account.id,
          responseData: lockCardResponse.data,
          message: `An error occurred while ${
            isCardLocked ? "locking" : "unlocking"
          } card`
        });
        toast(
          ToastType.ERROR,
          `Your request to ${isCardLocked ? "block" : "unblock"} your card ${
            isCardLocked ? "temporarily" : ""
          } has failed. Please try again`
        );
        setIsSubmitLoading(false);
        return;
      }
      const tempCard = { ...card };
      tempCard.isLocked = isCardLocked;
      dispatch(
        updateSelectedCard({
          card: tempCard
        })
      );
      const cardResponse = await AppApi.getCard(cardId!);
      if (cardResponse.status !== 200) {
        logger.error({
          correlationId: cardResponse.headers[CORRELATION_ID_HEADER],
          cardId,
          responseData: cardResponse.data,
          message: `An error occurred while getting card for app`
        });
        setIsLoading(false);
        return;
      }
      dispatch(
        setCustomerBlockCodes(
          getCustomerBlockCodeStatus({
            accountBlockCodes: account.blockCodes || null,
            cardBlockCodes: cardResponse.data?.blockCodes || null
          })
        )
      );
      isCardLocked
        ? toast(ToastType.SUCCESS, "Your card is temporarily blocked")
        : toast(ToastType.SUCCESS, "Your card is unblocked");
      setIsCardLocked(isCardLocked);
      setIsSubmitLoading(false);
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while ${
          isCardLocked ? "blocking" : "unblocking"
        } card`,
        error: error.message,
        accountId: account.id,
        stackTrace: error.stack
      });
      toast(
        ToastType.ERROR,
        `Your request to ${isCardLocked ? "block" : "unblock"} your card ${
          isCardLocked ? "temporarily" : ""
        } has failed. Please try again`
      );
    }
  };

  //Foot note button toggle
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  //Foot note array
  const allFootNotes = [
    "This feature is provided to you as an additional security measure for your Credit Card usage.",
    "The transaction limits set are same for Domestic and International. Control your card usage by enabling/disabling card controls on Domestic and International tabs.",
    "The limits will be displayed as Zero by default in case you have not set any Card level limits for transaction earlier. However, you can transact on your Credit Card up-to the assigned limits.",
    "We recommend you to keep your domestic POS transaction preference enabled to ensure uninterrupted usage of your Credit Card"
  ];

  const footNote = showMore ? allFootNotes : allFootNotes.slice(0, 2);

  return (
    <>
      {isLoading ? (
        <div style={{ height: "100vh" }}>
          <Loader text1="loading" text2="card controls" />
        </div>
      ) : (
        <>
          <Header bgColor="#141414" label="Card Controls" />
          <div className={styles.borderLine} />
          <div className={styles.cardControlsContainer}>
            <div
              className={
                (!isUnlockCardEnabled && isCardLocked) ||
                (!isLockCardEnabled && !isCardLocked)
                  ? styles.blockCardDisabledContainer
                  : styles.blockCardContainer
              }
            >
              <div className={styles.blockCardText}>
                Temporary Block Credit Card
              </div>
              <Switch
                handleDiameter={18}
                height={22}
                width={40}
                checkedIcon={false}
                offColor="#333333"
                onColor="#F4B348"
                uncheckedIcon={false}
                checked={isCardLocked}
                onChange={() => handleCardLock(!isCardLocked)}
                className={styles.reactSwitch}
                offHandleColor="#999999"
                disabled={
                  (!isUnlockCardEnabled && isCardLocked) ||
                  (!isLockCardEnabled && !isCardLocked)
                }
              />
            </div>
            <hr className={styles.horizontalLine}></hr>
            <div className={styles.cardControlsHeader}>
              <div className={styles.headerText1}>Set&nbsp;</div>
              <div className={styles.headerText2}>spend limits</div>
            </div>
            <div className={styles.cardControlSubHeader}>
              The set limits will be applicable per day, which means you can
              transact upto set limits each day.
            </div>
            <div className={styles.buttonContainer}>
              <div
                className={
                  (isCardLocked || !isDomesticEnabled) && isDomesticSelected
                    ? styles.disableButton
                    : isDomesticSelected
                    ? styles.buttonSelected
                    : styles.buttonNotSelected
                }
                onClick={() => {
                  !isCardLocked && setIsDomesticSelected(true);
                }}
              >
                Domestic
              </div>
              <div
                className={
                  (isCardLocked || !isInternationalEnabled) &&
                  !isDomesticSelected
                    ? styles.disableButton
                    : isDomesticSelected
                    ? styles.buttonNotSelected
                    : styles.buttonSelected
                }
                onClick={() => {
                  !isCardLocked && setIsDomesticSelected(false);
                }}
              >
                International
              </div>
            </div>
            <div className={styles.cardContainer}>
              {isDomesticSelected
                ? GetSettings("domestic", isDomesticEnabled)
                : GetSettings("international", isDomesticEnabled)}
            </div>
            <div className={styles.infoLimitsUniformText}>
              {/* The transaction limits set are same for Domestic and
              International. Control your card usage by enabling/disabling card
              controls on Domestic and/or International tabs */}
              {footNote.map((note, index) => {
                return (
                  <li key={index} className={styles.list}>
                    {note}
                  </li>
                );
              })}
            </div>
            <div className={styles.showMoreButton}>
              <div onClick={toggleShowMore}>
                {showMore ? "show less" : "show more"}{" "}
              </div>
            </div>
            {
              <div className={styles.btnContainer}>
                <Button2
                  isLoading={isSubmitLoading}
                  disabled={!haveCardControlsChanged()}
                  onClick={handleSubmitClick}
                  text1Style={{ fontWeight: "bold" }}
                  text1="confirm"
                  text2="changes"
                  customClassName={styles.seeAllBtn}
                />
              </div>
            }
          </div>
        </>
      )}
    </>
  );
};

export default CardControls;
