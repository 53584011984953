/*  eslint-disable react/jsx-key , react/no-unstable-nested-components */
import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "./smsVerify.module.scss";
import Status1 from "../../assets/images/verify-SIM.png";
import SimCard from "../../assets/svgs/SimCard.svg";
import ChatTearDrop from "../../assets/svgs/ChatTeardropText.svg";
import { Button2 } from "../button/Button";
import BottomsheetComponent from "../bottomSheet/bottomsheet";
import VerificationStage1 from "../../assets/svgs/step1activate.svg";
import VerificationStage2 from "../../assets/svgs/VerificationStage2.svg";
import VerificationStage2Verified from "../../assets/svgs/step2activate.svg";
import VerificationStage2Failed from "../../assets/svgs/VerifiedStage2Failed.svg";
import VerificationStage3 from "../../assets/svgs/VerificationStage3.svg";
import VerificationStage3Verified from "../../assets/svgs/step3activate.svg";
import VerificationStage3Failed from "../../assets/svgs/VerifiedStage3Failed.svg";
import _debounce from "lodash/debounce";
import OtpInput from "react-otp-input";
import step2Progress from "../../assets/svgs/step2Progress.svg";
import step3Progress from "../../assets/svgs/step3Progress.svg";
import { TextWithLinks } from "../textWithLinks/TextWithLinks";
import LottieView from "lottie-react";
import SmsLottie from "../../assets/LottieImages/sim-verification-steps.json";
import TimeLottie from "../../assets/LottieImages/sim-verify-timer-lottie.json";
import SmsVerifiedLottie from "../../assets/LottieImages/sim-binding-verified-ram.json";
import BackArrow from "../../assets/svgs/backArrow.svg";
import DeviceApi from "../../apis/deviceApi/deviceApi";
import { ToastType, toast } from "../../components";
import { toast as toastLib } from "react-toastify";
import {
  DEVICE_LIMIT,
  HOME_ROUTE,
  INSTALL_SCREEN,
  PREFETCH_FAILURE,
  CARD_BLOCKED
} from "../../routes/ScreenRoutes";
import {
  autoReadOtp,
  focusOnInput,
  getPWADisplayMode,
  createCookie,
  getUniqueURLFromCookie,
  cardBlockTimeForSmsRetry,
  cleanBase64String,
  getDeviceIdCookie,
  captureEvent
} from "../../utils/functions";
import {
  aesEncryptData,
  getPciEncryptionKeyAndIv,
  aesDecryptData
} from "../../utils/encryptionUtil";
import {
  GenerateLoginOtpSuccessResponse,
  VerifyLoginOtpSuccessResponse
} from "../../apis/loginApi/loginApiTypes";
import { ProgramPreferences } from "../../apis/prefrencesApi/preferencesApiTypes";
import LoginApi from "../../apis/loginApi/LoginApi";
import AppApi from "../../apis/appApi/AppApi";
import { EVENT_NAME } from "../../apis/appApi/appApiTypes";
import { setSession } from "../../features/session/sessionSlice";
import {
  setIsDeviceIdVerified,
  setUserIds
} from "../../features/user/userSlice";
import PreferencesApi from "../../apis/prefrencesApi/PreferencesApi";
import { getIssuerId } from "../../data/config";
import { setProgramPreferences } from "../../features/benefits/benefitsSlice";
import {
  getUserBrowserAndVersion,
  getUserOSAndVersion
} from "../../utils/trackingFunctions";
import {
  ADD_DEVICE_API_CALL_DELAY,
  ADD_DEVICE_API_CALL_TIMEOUT,
  RESEND_OTP_TIME,
  SMS_RETRY_ATTEMPTS
} from "../../utils/constants";
import Info from "../../assets/svgs/info.svg";
import GradientCircleProgressbar from "./circleProgressBar";
import {
  isManualOtpAllowed,
  smsSendingPhoneNumber
} from "../../utils/internalFlags";
import dayjs from "dayjs";
import { DeviceApiErrorCode, UserAction } from "../../utils/enums";
import SmsInfoImage from "../../assets/svgs/smsInfoImage.svg";
import { sendNotification } from "../../utils/notifyCustomer";

const SmsVerify = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const uniqueURL =
    params.get("uniqueURL")?.replaceAll(" ", "+") || getUniqueURLFromCookie();
  let smsVerificationStatus: any = "";
  const mobileVerificationRef = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const smsVerifyStepsLottieRef = useRef<HTMLInputElement>(null);
  const smsVerifiedLottieRef = useRef<HTMLInputElement>(null);
  const smsVerifiedLottie = JSON.parse(JSON.stringify(SmsVerifiedLottie));
  const smsVerifyStepsLottie = JSON.parse(JSON.stringify(SmsLottie));
  const step = Number(params.get("step")) || 1;
  const [isSmsStatusBottomsheet, setIsSmsStatusBottomsheet] =
    useState<boolean>(false);
  const [verifiedStage1, setVerifiedStage1] = useState<boolean>(true);
  const [resendCount, setResendCount] = useState(0);
  const [verifiedStage2, setVerifiedStage2] = useState<boolean | "failed">(
    false
  );
  const [verifiedStage3, setVerifiedStage3] = useState<boolean | "failed">(
    false
  );
  const [isSwitchSmsVerifiedSuccess, setIsSwitchSmsVerifiedSuccess] =
    useState<boolean>(false);
  const [stepsCount, setStepsCount] = useState<number>(step);
  const [stepText, setStepText] = useState("verify your SIM");
  const [cardNumber, setCardNumber] = useState<string>("");
  const [isGenerateLoginOtpLoading, setIsGenerateLoginOtpLoading] =
    useState<boolean>(false);
  const [isVerifyLoginOtpLoading, setIsVerifyLoginOtpLoading] =
    useState<boolean>(false);
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [otp, setOtp] = useState<string>("");

  const otpInputRef = useRef<OtpInput | null>(null);
  const line1 = useRef<HTMLDivElement>(null);
  const line2 = useRef<HTMLDivElement>(null);
  const [blockedUntil, setBlockedUntil] = useState<string>("");
  const [otpVerificationRefId, setOtpVerificationRefId] = useState<string>("");
  const mobileNumberRef = useRef<string>("");
  const [userOs, setUserOs] = useState<string>("");
  const [isCardDetailsNoMatch, setIsCardDetailsNoMatch] =
    useState<boolean>(false);
  const [timeOut, setTimeOut] = useState<number>(RESEND_OTP_TIME);
  const [isCopyTextEnabled, setIsCopyTextEnabled] = useState<boolean>(true);
  const [step2Text, setStep2Text] = useState<string>("SMS Sent");
  const [step2SubText, setStep2SubText] = useState<string>("");
  const [step3Text, setStep3Text] = useState<string>(
    "Mobile number verified 🎉"
  );
  const [smsRetryCount, setSmsRetryCount] =
    useState<number>(SMS_RETRY_ATTEMPTS);
  const [cardIncorrectAttemptLeft, setCardIncorrectAttemptLeft] =
    useState<string>("");
  const [isSmsVerificationFailed, setIsSmsVerificationFailed] =
    useState<boolean>(false);
  const [osVersion, setOsVersion] = useState<string>("");
  const [deviceName, setDeviceName] = useState<string>("");
  const [step2Image, setStep2Image] = useState(VerificationStage2);
  const [step3Image, setStep3Image] = useState(VerificationStage3);
  const [isSmsVerifiedBottomsheet, setIsSmsVerifiedBottomsheet] =
    useState<boolean>(false);
  const [isRedirectInfoBottomsheetOpen, setIsRedirectInfoBottomsheetOpen] =
    useState<boolean>(false);
  const [isSmsVerified, setIsSmsVerified] = useState<boolean>(false);

  useEffect(() => {
    const [os, version] = getUserOSAndVersion(navigator.userAgent);
    setUserOs(os);
    const [browser, browserversion] = getUserBrowserAndVersion(
      navigator.userAgent
    );

    if (os === "Android") {
      (navigator as any).userAgentData
        .getHighEntropyValues(["model", "platformVersion"])
        .then((values: any) => {
          setDeviceName(values?.model);
          setOsVersion(values?.platformVersion?.split(".")[0]);
        });
    }

    async function checkClipboardReadPermission(): Promise<boolean> {
      const permissionStatus = await navigator.permissions.query({
        name: "clipboard-read" as PermissionName
      });
      return permissionStatus.state === "granted";
    }

    // Usage
    if (os === "Android" && browser === "Google Chrome") {
      checkClipboardReadPermission().then((isAllowed) => {
        if (isAllowed) {
          setIsCopyTextEnabled(false);
        }
      });
    }
  }, [step]);

  useEffect(() => {
    setTimeout(() => {
      verifiedStage2
        ? verifiedStage2 === "failed"
          ? setStep2Image(VerificationStage2Failed)
          : setStep2Image(VerificationStage2Verified)
        : setStep2Image(VerificationStage2);
    }, 500);
  }, [verifiedStage2]);

  useEffect(() => {
    return () => {
      if (smsVerifyStepsLottieRef.current) {
        smsVerifyStepsLottieRef.current.innerHTML = "";
      }
    };
  }, [stepsCount]);

  useEffect(() => {
    return () => {
      if (smsVerifiedLottieRef.current) {
        smsVerifiedLottieRef.current.innerHTML = "";
      }
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      verifiedStage3
        ? verifiedStage3 === "failed"
          ? setStep3Image(VerificationStage3Failed)
          : setStep3Image(VerificationStage3Verified)
        : setStep3Image(VerificationStage3);
    }, 500);
  }, [verifiedStage3]);

  useEffect(() => {
    // Start the interval
    let interval: any;
    if (stepsCount === 3 && timeOut > 0) {
      interval = setTimeout(() => {
        setTimeOut(timeOut - 1);
      }, 1000);
    }

    // Return a cleanup function to clear the interval
    return () => clearInterval(interval);
  }, [timeOut, stepsCount]);

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (stepsCount === 3) {
          !isVerifyLoginOtpLoading &&
            otp.length === 6 &&
            handleOtpVerifyClick(otp);
        } else if (stepsCount === 2) {
          !isGenerateLoginOtpLoading &&
            cardNumber.length === 4 &&
            stepsCount === 2 &&
            debounceSendOtp();
        }
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [otp, cardNumber]);



  useEffect(() => {
    if (Number(params.get("step"))) {
      setIsSmsVerifiedBottomsheet(true);
    }
  }, []);

  const tncText =
    "By entering OTP and proceeding, I agree to the (Terms & Conditions)[https://www.indusind.com/in/en/personal/terms-and-conditions.html] and (Privacy Policy)[https://www.indusind.com/in/en/personal/privacy-policy.html] associated with IndusInd Bank Credit Card setup";

  const SmsStatusBottomSheet = () =>
    isSwitchSmsVerifiedSuccess !== true ? (
      <>
        <div
          className={`${styles.bottomSheet} ${
            isSmsVerificationFailed ? styles.additionalHeight : ""
          } ${styles.transitionForSms}`}
        >
          <div className={styles.bottomSheetContainer}>
            <div className={styles.verificationSteps}>
              <img src={VerificationStage1} alt="Stage 1" />
              <div
                className={`${
                  verifiedStage1 ? styles.verified : styles.notVerified
                }`}
              >
                Verification Initiated
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                className={`${styles.line} ${
                  verifiedStage2 ? styles.lineAnimate : ""
                }`}
                ref={line1}
              ></div>
              <div className={styles.verticalLine1}>
                {verifiedStage2 === false && (
                  <div
                    className={styles.verificationStep1SubText}
                    style={{ marginTop: "-5%", marginLeft: "10%" }}
                  >
                    <LottieView
                      animationData={TimeLottie}
                      autoPlay
                      loop={true}
                      className={styles.timerLottie}
                    />{" "}
                    Press ‘Send SMS’ within 5 seconds
                  </div>
                )}
              </div>
            </div>
            <div className={styles.verificationSteps}>
              <img
                src={step2Image}
                alt="Stage 2"
                style={{ transition: "opacity 6s ease-in-out" }}
              />
              <div
                className={`${
                  verifiedStage2 ? styles.verified : styles.notVerified
                }`}
              >
                {step2Text}
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                className={`${styles.line} ${
                  verifiedStage3 ? styles.lineAnimate : ""
                }`}
                ref={line2}
              ></div>
              <div
                className={`${styles.verticalLine1} ${styles.verificationStep2SubText}`}
                style={{ marginTop: "-8px" }}
              >
                {step2SubText}
              </div>
            </div>
            <div className={styles.verificationSteps}>
              <img
                src={step3Image}
                alt="Stage 3"
                style={{ transition: "opacity 6s ease-in-out" }}
              />
              <div
                className={`${
                  verifiedStage3 ? styles.verified : styles.notVerified
                }`}
              >
                {step3Text}
              </div>
            </div>
            {verifiedStage3 === true ? (
              <div className={styles.smsVerifiedSucessSubText3}>
                and device registered
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {isSmsVerificationFailed && (
          <div className={styles.confirmBtn1ContainerSmsRetry}>
            <Button2
              text1="retry"
              text1Style={{ fontWeight: "bold" }}
              customClassName={styles.retryButton}
              onClick={handleContinueToSendSmsClick}
            />
          </div>
        )}
      </>
    ) : (
      <>
        <div className={styles.bottomSheet}>
          <div className={styles.bottomSheetContainer}>
            <div
              ref={smsVerifiedLottieRef}
              className={styles.simBindingSuccess}
            >
              <LottieView
                animationData={smsVerifiedLottie}
                onComplete={handleSmsVerifyAnimationEnd}
                loop={false}
                className={styles.smsVerifiedLottie}
              />
            </div>
            <div className={styles.smsVerifiedSucessTextContainer}>
              <div
                className={styles.smsVerifiedSucessSubText1}
                style={{ backgroundColor: "transparent" }}
              >
                Mobile number verified
              </div>
              <div className={styles.smsVerifiedSucessSubText2}>
                and device registered
              </div>
            </div>
          </div>
        </div>
      </>
    );

  const SmsVerifiedBottomsheet = () => (
    <>
      <div className={styles.bottomSheet}>
        <div className={styles.bottomSheetContainer}>
          <div ref={smsVerifiedLottieRef} className={styles.simBindingSuccess}>
            <LottieView
              animationData={smsVerifiedLottie}
              onComplete={handleSmsVerifyAnimationEnd}
              loop={false}
              className={styles.smsVerifiedLottie}
            />
          </div>
          <div className={styles.smsVerifiedSucessTextContainer}>
            <div
              className={styles.smsVerifiedSucessSubText1}
              style={{ backgroundColor: "transparent" }}
            >
              Mobile number verified
            </div>
            <div className={styles.smsVerifiedSucessSubText2}>
              and device registered
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const RedirectBackInfoBottomsheet = () => (
    <>
      <div className={styles.bottomSheetInfo}>
        <div className={styles.bottomSheetContainer}>
          <div className={styles.infoBottomsheetImage}>
            <img src={SmsInfoImage} alt="" />
          </div>
          <div className={styles.redirectBannerText}>
            Redirecting you to the{" "}
            <span className={styles.redirectBannerText2}>
              SMS app on your phone...
            </span>
          </div>
          <div className={styles.infoTimerText}>
            <LottieView
              animationData={TimeLottie}
              autoPlay
              loop={true}
              className={styles.timerLottie}
            />{" "}
            Press ‘Send SMS’ within 5 seconds
          </div>
        </div>
        <div className={styles.infoTextToRedirectBack}>
          Once done, return to this page for next steps.
        </div>
      </div>
    </>
  );

  const handleAutoRead = () => {
    if (userOs !== "Android") {
      autoReadOtp((otpValue) => {
        setOtp(otpValue);
        handleInputResponsive();
        focusOnInput("loginOtpInputContainer", 5);
      });
    }
  };

  const handleSmsVerifyAnimationEnd = () => {
    setIsSmsStatusBottomsheet(false);
    setIsSmsVerifiedBottomsheet(false);
    setStepsCount(2);
  };

  const handleCard = (num: string) => {
    if (isInputValid(num)) setCardNumber(num);
  };

  const handleBackArrowClick = () => {
    if (stepsCount === 3) {
      setStepsCount((prev: number) => prev - 1);
    }

    setOtp("");
  };

  const handleChange = (code: string) => {
    // window.alert(code);
    handleInputResponsive();
    setOtp(code);
  };

  const handleInputResponsive = () => {
    const inputElement = inputRef.current;
    if (inputElement) {
      const inputWidth = inputElement.offsetWidth;
      // Calculate the effective character width considering the font size
      const charWidth = inputWidth / 6;
      // Adjust the letterSpacing based on the calculated charWidth and desired spacing
      const letterSpacing = Math.max(Math.floor(charWidth * 0.7), 1); // Adjust multiplier (0.8 here) based on your needs
      inputElement.style.letterSpacing = letterSpacing + "px";
    }
  };

  const handleKeyDown = (event: any) => {
    if (/\d/g.test(event.key) && !isManualOtpAllowed) {
      // Prevent the default action (entering the number)
      event.preventDefault();
      setOtp("");
    }
  };

  const numberRegex = /^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/;
  const isInputValid = (num: string) => {
    if (num.includes(".")) return false;
    return numberRegex.test(num);
  };

  const captureOtpGenerateEvent = (
    mobileNumber: string,
    cardNumber: string,
    status: boolean,
    message: string
  ) => {
    AppApi.captureEvent({
      eventName: EVENT_NAME.OTP_GENERATE,
      primarySource: window.location.href,
      eventData: {
        otpGeneratedStatus: status,
        ...(status && { ["responseMessage"]: message }),
        mobileNumber: mobileNumber,
        cardNumber: cardNumber
      },
      sessionMetaData: {
        referrer: window.document.referrer
      }
    });
  };

  const captureResendOtpEvent = (
    mobileNumber: string,
    cardNumber: string,
    resendCount: number
  ) => {
    AppApi.captureEvent({
      eventName: EVENT_NAME.RESEND_OTP,
      primarySource: window.location.href,
      eventData: {
        resendOtpCount: resendCount,
        mobileNumber: mobileNumber,
        cardNumber: cardNumber
      },
      sessionMetaData: {
        referrer: window.document.referrer
      }
    });
  };

  const captureOtpVerifyClick = (mobileNumber: string, cardNumber: string) => {
    AppApi.captureEvent({
      eventName: EVENT_NAME.OTP_VERIFY,
      primarySource: window.location.href,
      eventData: {
        mobileNumber: mobileNumber,
        cardNumber: cardNumber
      },
      sessionMetaData: {
        referrer: window.document.referrer
      }
    });
  };

  const captureLoginFailureEvent = (
    loginFailureReason: string,
    mobileNumber: string,
    cardNumber: string
  ) => {
    AppApi.captureEvent({
      eventName: EVENT_NAME.LOGIN_FAILURE,
      primarySource: window.location.href,
      eventData: {
        loginFailureReason: loginFailureReason,
        mobileNumber: mobileNumber,
        cardNumber: cardNumber
      },
      sessionMetaData: {
        referrer: window.document.referrer
      }
    });
  };

  const captureLoginSuccessEvent = (
    customerId: string,
    selectedCardId: string,
    accountId: string
  ) => {
    AppApi.captureEvent({
      eventName: EVENT_NAME.LOGIN_SUCCESS,
      hfAccountId: accountId,
      hfCardId: selectedCardId,
      hfCustomerId: customerId,
      primarySource: window.location.href,
      eventData: {
        loginMode: getPWADisplayMode() === "app" ? "App" : "URL"
      },
      sessionMetaData: {
        referrer: window.document.referrer
      }
    });
  };

  const handleAddDevice = async (
    uniqueURL: string,
    intervalId: any,
    smsSentTime: string = ""
  ) => {
    try {
      const response = await DeviceApi.addDevice({
        uniqueURL: uniqueURL || getUniqueURLFromCookie(),
        smsSentTime: smsSentTime,
        checkSMS: true,
        osVersion: osVersion,
        deviceName: deviceName
      });

      if (
        response?.data?.status === "SUCCESS" ||
        response?.data?.errorCode ===
          DeviceApiErrorCode.DEVICE_ALREADY_REGISTERED
      ) {
        
        setIsDeviceIdVerified(true);
        createCookie(
          "deviceId",
          response?.data?.deviceId,
          "Tue, 19 Jan 2038 04:14:07 IST"
        );

        createCookie("uniqueURL", uniqueURL, "Tue, 19 Jan 2038 04:14:07 IST");

        handleSmsVerified();
        sendNotification(UserAction.REGISTER, {
          uniqueURL: getUniqueURLFromCookie(),
          deviceId: response?.data?.deviceId
        });
        clearInterval(intervalId);
        captureEvent({eventName:EVENT_NAME.DEVICE_REGISTERED,eventData:{uniqueURL:getUniqueURLFromCookie()}})
      } else if (
        response?.data?.errorCode === DeviceApiErrorCode.DEVICE_LIMIT_REACHED
      ) {
        handleSmsVerified(response?.data?.errorCode);
        setTimeout(() => {
          navigate(DEVICE_LIMIT, {
            state: {
              registeredDevices: response?.data?.registeredDevices,
              uniqueURL: uniqueURL
            }
          });
          setStepsCount(2);
        }, 2500);
        clearInterval(intervalId);
      } else if (
        response?.data?.errorCode === DeviceApiErrorCode.SMS_NOT_FOUND ||
        response?.data?.errorCode === DeviceApiErrorCode.SMS_NOT_MATCHING
      ) {
        smsVerificationStatus = response?.data?.errorCode;
      }
    } catch (error: any) {
      console.error(
        `An exception occurred while adding device. Error:\n${error}`
      );
      toastLib.dismiss();
      setTimeout(() => {
        toast(
          ToastType.ERROR,
          "We are unable to process your request. Please try again later"
        );
      }, 1000);
    }
  };

  const sendSms = async () => {
    const phoneNumber = smsSendingPhoneNumber;
    const value = {
      uniqueURL: uniqueURL
    };

    const newMessage = `${aesEncryptData(
      process.env.REACT_APP_PII_ENCRYPTION_KEY!,
      process.env.REACT_APP_PII_ENCRYPTION_IV!,
      "HF-SIM-BINDING",
      false
    )} ${aesEncryptData(
      process.env.REACT_APP_PII_ENCRYPTION_KEY!,
      process.env.REACT_APP_PII_ENCRYPTION_IV!,
      JSON.stringify(value)
    )}`.trim();

    const message = cleanBase64String(newMessage);
    // Detect if the device is Android or iOS
    const isAndroid = /android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    let smsUrl;

    if (isAndroid) {
      // For Android devices
      smsUrl = `sms:${phoneNumber}?body=${encodeURIComponent(message)}`;
    } else if (isIOS) {
      // For iOS devices
      smsUrl = `sms:${phoneNumber}&body=${encodeURIComponent(message)}`;
    } else {
      // Default SMS URL
      smsUrl = `sms:${phoneNumber}?body=${encodeURIComponent(message)}`;
    }

    // Open the SMS URL
    window.location.href = smsUrl;
  };

  const handleSmsVerified = (errorCode: any = "") => {
    setVerifiedStage2(true);
    if (line1.current) {
      line1.current.style.backgroundColor = "#f4b348";
    }
    setIsSmsVerificationFailed(false);
    setTimeout(() => {
      if (line2.current) {
        line2.current.style.backgroundColor = "#f4b348";
      }
      setVerifiedStage3(true);
    }, 500);
    setIsSmsVerified(true);
    if (errorCode === DeviceApiErrorCode.DEVICE_LIMIT_REACHED) {
      return;
    }
    setTimeout(() => {
      setIsSwitchSmsVerifiedSuccess(true);
    }, 1500);
    // setTimeout(() => {
    //   setIsSmsStatusBottomsheet(false);
    //   setStepsCount(2);
    // }, 3500);
  };

  const handleSmsVerification = () => {
    let smsSentTime: string = dayjs().format("YYYY-MM-DD HH:mm:ss");
    setSmsRetryCount(smsRetryCount - 1);
    const intervalId = setInterval(() => {
      handleAddDevice(uniqueURL!, intervalId, smsSentTime);
    }, ADD_DEVICE_API_CALL_DELAY);
    setTimeout(() => {
      clearInterval(intervalId);
      if (isSmsVerified) {
        return;
      }
      if (smsRetryCount <= 0) {
        if (
          Number(smsVerificationStatus) === DeviceApiErrorCode.SMS_NOT_FOUND
        ) {
          setStep2Text("SMS was not sent");
          setStep2SubText(
            `You have exhausted all your attempts. Please try again in 24 hours.`
          );
          setVerifiedStage2("failed");
          setIsSmsVerificationFailed(true);
          setTimeout(() => {
            createCookie(
              "blockedCardTime",
              cardBlockTimeForSmsRetry(),
              "Tue, 19 Jan 2038 04:14:07 IST"
            );
            navigate(CARD_BLOCKED);
            setIsSmsStatusBottomsheet(false);
          }, 5000);
          if (line1.current) {
            line1.current.style.backgroundColor = "#f4b348";
          }
          setIsSmsVerificationFailed(false);
          setTimeout(() => {
            if (line2.current) {
              line2.current.style.backgroundColor = "rgba(230, 76, 102, 1)";
            }
            setVerifiedStage3("failed");
            setStep3Text("Verification Failed");
          }, 500);
        } else {
          setStep2Text("Details did not match");
          setStep2SubText(`You have exhausted all your attempts. `);
          setVerifiedStage2("failed");
          setIsSmsVerificationFailed(true);
          setTimeout(() => {
            createCookie(
              "blockedCardTime",
              cardBlockTimeForSmsRetry(),
              "Tue, 19 Jan 2038 04:14:07 IST"
            );
            navigate(CARD_BLOCKED);
            setIsSmsStatusBottomsheet(false);
          }, 5000);
          if (line1.current) {
            line1.current.style.backgroundColor = "#f4b348";
          }
          setIsSmsVerificationFailed(false);
          setTimeout(() => {
            if (line2.current) {
              line2.current.style.backgroundColor = "rgba(230, 76, 102, 1)";
            }
            setVerifiedStage3("failed");
            setStep3Text("Verification Failed");
          }, 500);
        }
      } else if (Number(smsVerificationStatus) === 410) {
        setStep2Text("SMS was not sent");
        setVerifiedStage2("failed");
        setStep2SubText(`You have ${smsRetryCount} attempt left`);
        setIsSmsVerificationFailed(true);
        if (line1.current) {
          line1.current.style.backgroundColor = "#f4b348";
        }
      } else if (Number(smsVerificationStatus) === 411) {
        setStep2Text("Details did not match");
        setVerifiedStage2("failed");
        setStep2SubText(
          `Make sure the SMS is sent from your registered mobile number.${smsRetryCount} attempt left`
        );
        setIsSmsVerificationFailed(true);
      }
    }, ADD_DEVICE_API_CALL_TIMEOUT);
  };

  const handleContinueToSendSmsClick = () => {
    sendSms();
    setVerifiedStage2(false);
    setStep2Image(VerificationStage2);
    setVerifiedStage3(false);
    setStep2Text("SMS was sent");
    setStep2SubText("");
    setIsSmsVerificationFailed(false);
    setIsSmsStatusBottomsheet(true);
    if (line1.current) {
      line1.current.style.backgroundColor = "rgb(145, 138, 138)";
    }
    handleSmsVerification();
    captureEvent({eventName:EVENT_NAME.SMS_VERIFICATION_RETRY_CLICK,eventData:{uniqueURL:getUniqueURLFromCookie()}})
  };

  const handleSendSmsClick = () => {
    setIsRedirectInfoBottomsheetOpen(true);
    setTimeout(() => {
      setIsRedirectInfoBottomsheetOpen(false);
      handleContinueToSendSmsClick();
    }, 3000);
  };

  const handleGenerateOtpClick = async () => {
    try {
      setIsGenerateLoginOtpLoading(true);
      mobileNumberRef.current = mobileNumber;
      const response = await LoginApi.generateLoginOtp({
        uniqueURL: getUniqueURLFromCookie() || "",
        cardLastFour: cardNumber
      });

      captureOtpGenerateEvent(
        mobileNumber,
        cardNumber,
        true,
        (response.data as any)?.code ||
          (response.data as any)?.status ||
          "OTP is sent successfully"
      );

      if (response.status === 200) {
        setTimeout(() => {
          toastLib.dismiss();
          // eslint-disable-next-line
          setTimeout(() => {}, 1000);
          toast(ToastType.SUCCESS, "OTP is sent successfully");
        }, 1000);
        const result = response.data as GenerateLoginOtpSuccessResponse;
        setOtpVerificationRefId(result.mobileVerificationRefId);
        setOtpVerificationRefId(result.mobileVerificationRefId);
        mobileVerificationRef.current = result.mobileVerificationRefId;
        sessionStorage.setItem(
          "mobileNumberlastFour",
          response?.data?.lastFour
        );
        setMobileNumber(response?.data?.lastFour);
        setStepsCount(3);
        toastLib.dismiss();

        autoReadOtp((otpValue) => {
          // handleOtpVerifyClick(otpValue);
          setOtp(otpValue);
          handleInputResponsive();
          focusOnInput("loginOtpInputContainer", 5);
        });
      } else if ((response.data as any)?.code === "IBL_001") {
        setIsCardDetailsNoMatch(true);
        setCardIncorrectAttemptLeft((response?.data as any)?.attemptsLeft);
        console.error("Rejected generate login OTP for add on card.");
        setTimeout(() => {
          toast(
            ToastType.INFO,
            "Please login with last 4 digits of Primary Card"
          );
        }, 1000);
      } else if ((response.data as any)?.code === "IBL_002") {
        setIsCardDetailsNoMatch(true);
        setCardIncorrectAttemptLeft((response?.data as any)?.attemptsLeft);
        console.error("Rejected generate login OTP for this account.");
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "The OTP generation has failed. Please enter the correct details"
          );
        }, 1000);
      } else if ((response.data as any)?.code === "IBL_003") {
        setIsCardDetailsNoMatch(true);
        setCardIncorrectAttemptLeft((response?.data as any)?.attemptsLeft);
        console.error("Rejected generate login OTP for this customer.");
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "The OTP generation has failed. Please enter the correct details"
          );
        }, 1000);
      } else if ((response.data as any)?.code === "IBL_004") {
        setIsCardDetailsNoMatch(true);
        setCardIncorrectAttemptLeft((response?.data as any)?.attemptsLeft);
        console.error("Rejected generate login OTP for this card.");
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "The OTP generation has failed. Please enter the correct details"
          );
        }, 1000);
      } else if (
        (response.data as any)?.status === "RETRIES_EXCEEDED" ||
        (response.data as any)?.status === "BLOCKED_TEMPORARILY"
      ) {
        setIsCardDetailsNoMatch(true);
        setBlockedUntil((response.data as any)?.blockedUntil);
        createCookie(
          "blockedCardTime",
          (response.data as any)?.blockedUntil,
          "Tue, 19 Jan 2038 04:14:07 IST"
        );
        navigate(CARD_BLOCKED);
        const time = (response.data as any)?.failureReason
          ?.match(/Please retry after (.*)./)?.[1]
          ?.split(",");

        const isMinutesPresent =
          (response.data as any)?.failureReason?.includes("Minutes") ||
          (response.data as any)?.failureReason?.includes("minutes");

        const retryTime =
          !!time && isMinutesPresent ? time?.[0].split(" ")?.[0] : "1";

        console.error(
          `Failed to generate login OTP. Retries exceeded, ${
            (response.data as any)?.status
          }`
        );
        toastLib.dismiss();
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            (response.data as any)?.failureReason.includes("temporarily")
              ? `Maximum number of OTP attempts exceeded. Please try again after ${retryTime} ${
                  retryTime < 2 ? "min" : "mins"
                }.`
              : `Maximum number of login attempts exceeded. Please try again after ${retryTime} ${
                  retryTime < 2 ? "min" : "mins"
                }.`
          );
        }, 1000);
      } else {
        console.error("An error occurred while generating login OTP");
        toastLib.dismiss();
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "The OTP generation has failed. Please try again"
          );
        }, 1000);
      }
    } catch (error: any) {
      // logger.error({
      //   message: `An exception occurred while generating login otp`,
      //   error: error.message,
      //     mobileNumber: getMaskedMobileNumberForLogs(phone),
      //   stackTrace: error.stack
      // });
      console.error(
        `An exception occurred while generating login OTP. Error:\n${error}`
      );
      toastLib.dismiss();
      setTimeout(() => {
        toast(
          ToastType.ERROR,
          "We are unable to process your request. Please try again later"
        );
      }, 1000);
    }
    setIsGenerateLoginOtpLoading(false);
  };

  const handleResendOtpClick = () => {
    setTimeOut(RESEND_OTP_TIME);
    toastLib.dismiss();
    if (resendCount < 3) {
      setResendCount((resendCount) => resendCount + 1);
      setTimeout(() => {
        toast(ToastType.SUCCESS, "Resending OTP");
      }, 500);
    }

    captureResendOtpEvent(mobileNumber, cardNumber, resendCount + 1);
    debounceSendOtp();
  };

  const debounceSendOtp = _debounce(handleGenerateOtpClick, 500);

  const handleOtpVerifyClick = async (otp: string) => {
    captureOtpVerifyClick(mobileNumber, cardNumber); // capturing otp verify click
    try {
      // if (otp.length !== 6) {
      //   setIsVerifyLoginOtpLoading(false);
      //   autoReadOtp((otpValue) => {
      //     setOtp(otpValue);
      //     handleOtpVerifyClick(otpValue);
      //     focusOnInput("loginOtpInputContainer", 5);
      //   });
      //   return;
      // }
      const verifyLoginOtpresponse = (await LoginApi.verifyLoginOtp({
        mobileVerificationRefId: mobileVerificationRef.current,
        otp: "" + otp
      })) as any;
      toastLib.dismiss();
      if (verifyLoginOtpresponse.status !== 200) {
        captureLoginFailureEvent(
          verifyLoginOtpresponse?.data?.failureReason,
          mobileNumber,
          cardNumber
        ); //capturing Login failure event
        if (verifyLoginOtpresponse.data.failureReason === "INVALID_OTP") {
          setTimeout(() => {
            toast(
              ToastType.ERROR,
              "The OTP entered is incorrect. Please try again"
            );
          }, 1000);
          setTimeout(() => {
            setOtp("");
            if (otpInputRef.current) {
              otpInputRef.current.focusInput(0);
            }
          }, 1500);
        } else if (
          verifyLoginOtpresponse.data.failureReason === "OTP_EXPIRED"
        ) {
          setTimeout(() => {
            setOtp("");
            toast(ToastType.ERROR, "The OTP has expired. Please try again");
          }, 1000);
        } else {
          setTimeout(() => {
            setOtp("");
            toast(
              ToastType.ERROR,
              "The OTP verification has failed. Please try again"
            );
          }, 1000);
        }
        setIsVerifyLoginOtpLoading(false);
        return;
      }

      const verifyLoginOtpResult =
        verifyLoginOtpresponse.data as VerifyLoginOtpSuccessResponse;

      if (
        !verifyLoginOtpResult.customerId ||
        !verifyLoginOtpResult.programId ||
        !verifyLoginOtpResult.accountId ||
        !verifyLoginOtpResult.cardId
      ) {
        console.log(
          "An error occurred while verifying login OTP. Required ids missing in response."
        );
        setIsVerifyLoginOtpLoading(false);
        toastLib.dismiss();
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "The OTP verification has failed. Please try again"
          );
        }, 1000);
        return;
      }

      if (getPWADisplayMode() === "app") {
        sendNotification(UserAction.LOGIN, {
          uniqueURL: getUniqueURLFromCookie(),
          deviceId: getDeviceIdCookie()
        });
      }

      const { token, metaData } = verifyLoginOtpResult;

      const { pciEncryptionKey, pciEncryptionIv } = getPciEncryptionKeyAndIv(
        token,
        metaData
      );

      const sessionData = {
        token,
        metaData,
        pciEncryptionKey,
        pciEncryptionIv
      };

      captureLoginSuccessEvent(
        verifyLoginOtpResult.customerId,
        verifyLoginOtpResult.cardId,
        verifyLoginOtpResult.accountId
      ); // capturing login success event

      if (
        !verifyLoginOtpresponse.data.lagTimeForInstallPrompt ||
        !verifyLoginOtpresponse.data.showPrompt
      ) {
        sessionStorage.setItem("showPrompt", "false");
      } else {
        sessionStorage.setItem(
          "showPrompt",
          verifyLoginOtpresponse.data.showPrompt
        );
        sessionStorage.setItem(
          "lagTimeForInstallPrompt",
          verifyLoginOtpresponse.data.lagTimeForInstallPrompt
        );
      }

      const userIdsData = {
        customerId: verifyLoginOtpResult.customerId,
        selectedProgramId: verifyLoginOtpResult.programId,
        selectedAccountId: verifyLoginOtpResult.accountId,
        selectedCardId: verifyLoginOtpResult.cardId
      };

      if (!verifyLoginOtpResult.isFirstTimeLogin) {
        // if not first login then set data in store instead of showing preferences bottom sheet
        localStorage.setItem("isFirstTimeLogin", "false");
        dispatch(setSession(sessionData!));
        dispatch(setUserIds(userIdsData!));
        if (!verifyLoginOtpResult.isPriorityRefreshSuccess) {
          navigate(PREFETCH_FAILURE);
          return;
        }
        if (getPWADisplayMode()) {
          navigate(HOME_ROUTE);
          return;
        }
        navigate(INSTALL_SCREEN);
        setIsVerifyLoginOtpLoading(false);
        return;
      }

      // save data in state but not yet in store to show favorites screen
      // setSessionState(sessionData);
      // setUserIdsState(userIdsData);
      if (!verifyLoginOtpResult.isPriorityRefreshSuccess) {
        navigate(PREFETCH_FAILURE);
        return;
      }

      // get program preferences
      const getProgramPreferencesResponse =
        await PreferencesApi.getProgramPreferences(verifyLoginOtpResult.token, {
          programId: verifyLoginOtpResult.programId,
          issuerId: getIssuerId()
        });

      if (getProgramPreferencesResponse.status !== 200) {
        console.log("An error occurred while getting program preferences");
        toastLib.dismiss();
        setTimeout(() => {
          toast(
            ToastType.ERROR,
            "We are unable to display your favourites. Please try again"
          );
        }, 1000);
        setIsVerifyLoginOtpLoading(false);
        return;
      }

      let programPreferenceObj: ProgramPreferences = {
        id: "",
        programId: "",
        txnPostingDelayForComputation: 0,
        computeAndReverse: false,
        webHookEndPoint: "",
        webhookSecretKey: "",
        tags: getProgramPreferencesResponse?.data,
        createdAt: "",
        updatedAt: ""
      };

      dispatch(setProgramPreferences(programPreferenceObj));

      // setIsFavouriteBottomSheetOpen(true);
    } catch (error: any) {
      toastLib.dismiss();
      console.error(
        `An exception occurred while verifying login OTP. Error:\n${error}`
      );
      setTimeout(() => {
        toast(
          ToastType.ERROR,
          "We are unable to display your favourites. Please try again"
        );
      }, 1000);
    }
  };

  if (stepsCount === 2) {
    return (
      <>
        <div className={styles.header}>
          {/* <div className={styles.backArrow} onClick={handleBackArrowClick}>
            <img src={BackArrow} alt="" />
          </div> */}
          <div className={styles.headerTab}>
            <div className={styles.statusLogo}>
              <img
                src={step2Progress}
                alt="Status Image"
                className={styles.statusImg}
              />
            </div>
            <div className={styles.headerText}>
              <div className={styles.stepsCount}>Step {`${stepsCount}`}/3</div>
              <div className={styles.stepsText}>enter card details</div>
            </div>
          </div>
        </div>

        <div className={styles.cardDetailsEnter}>
          <div className={styles.phoneNumberContainer}>
            <span className={styles.cardDetailsText}>
              Enter last 4 digits of your credit card
            </span>
            <div className={styles.cardDetailsInput}>
              <OtpInput
                value={cardNumber}
                onChange={handleCard}
                numInputs={4}
                separator={<span style={{ width: "30px" }}></span>}
                isInputNum={true}
                shouldAutoFocus={true}
                inputStyle={{
                  border: "0",
                  borderBottom: `2px solid ${
                    isCardDetailsNoMatch ? "red" : "#353535"
                  }`,
                  color: "white",
                  width: "40px",
                  padding: "0 5px",
                  marginRight: "10px",
                  textAlign: "center",
                  fontSize: "2.5em",
                  cursor: "pointer",
                  willChange: "border",
                  transition: "border .3s ease-in-out",
                  WebkitAppearance: "none",
                  MozAppearance: "textfield",
                  background: "transparent",
                  outline: "none"
                }}
                focusStyle={{
                  borderBottom: `1px solid ${
                    isCardDetailsNoMatch ? "red" : "#4e4e4e"
                  }`,
                  outline: "none"
                }}
                ref={otpInputRef}
              />
            </div>
            {isCardDetailsNoMatch && (
              <span className={styles.noMatchText}>
                Details did not match. You have {cardIncorrectAttemptLeft}{" "}
                attempts left.
              </span>
            )}
          </div>
          <div className={styles.confirmBtn1Container2}>
            <Button2
              text1="confirm"
              text1Style={{ fontWeight: "bold" }}
              customClassName={styles.confirmBtn1}
              isLoading={isGenerateLoginOtpLoading}
              onClick={() => debounceSendOtp()}
              disabled={cardNumber.length !== 4 || isGenerateLoginOtpLoading}
            />
          </div>
        </div>

        <BottomsheetComponent
          isBottomSheetOpen={isSmsStatusBottomsheet}
          setIsBottomSheetOpen={setIsSmsStatusBottomsheet}
          render={SmsStatusBottomSheet()}
        />
      </>
    );
  } else if (stepsCount === 3) {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.backArrow} onClick={handleBackArrowClick}>
            <img src={BackArrow} alt="" />
          </div>
          <div
            className={styles.headerTab}
            style={{ marginTop: "2%", marginLeft: "5%" }}
          >
            <div className={styles.statusLogo}>
              <img
                src={step3Progress}
                alt="Status Image"
                className={styles.statusImg}
              />
            </div>
            <div className={styles.headerText}>
              <div className={styles.stepsCount}>Step {`${stepsCount}`}/3</div>
              <div className={styles.stepsText}>verify with OTP</div>
            </div>
          </div>
        </div>

        <div className={styles.cardDetailsEnter}>
          <div className={styles.phoneNumberContainer}>
            <span className={styles.cardDetailsText}>
              Enter the 6 digit code sent on +91 ***** **{" "}
              {mobileNumber.slice(-3)}
            </span>
            <div id="loginOtpInputContainer" className={styles.otpContainer}>
              <div className={styles.phoneNumberInputContainer}>
                <input
                  id="inputPhone"
                  type="text"
                  inputMode="numeric"
                  autoComplete="one-time-code"
                  placeholder="------"
                  className={styles.phoneNumberInput}
                  style={{ opacity: otp.length > 0 ? 1 : 0.5 }}
                  value={otp}
                  maxLength={6}
                  onChange={(e: any) => handleChange(e.target.value)}
                  onKeyDown={handleKeyDown}
                  readOnly={userOs === "Android" && !isManualOtpAllowed}
                  ref={inputRef}
                  autoFocus={userOs !== "Android"}
                  onFocus={handleAutoRead}
                />
              </div>
              <div className={styles.resendContainer}>
                <div>Didn't get the OTP?</div>
                <div className={styles.resendText}>
                  {timeOut !== 0 ? (
                    <span className={styles.retryText}>
                      Retry in 00:{timeOut.toString().padStart(2, "0")}s
                    </span>
                  ) : (
                    <>
                      <span onClick={handleResendOtpClick}>Resend OTP</span>
                    </>
                  )}
                </div>
              </div>
              {isCopyTextEnabled && (
                <>
                  <div className={styles.copyText}>
                    <img src={Info} alt="" />
                    Allow permission to auto-read OTP. It helps us verify it’s
                    really you.
                  </div>
                </>
              )}
              <div className={styles.tncContainer}>
                <div className={styles.tncText}>
                  <TextWithLinks
                    text={tncText}
                    linkClassName={styles.linkText}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.confirmBtn1Container2}>
            <Button2
              text1="confirm"
              text1Style={{ fontWeight: "bold" }}
              customClassName={styles.confirmBtn1}
              isLoading={isGenerateLoginOtpLoading}
              onClick={() => handleOtpVerifyClick(otp)}
              disabled={otp.length !== 6}
            />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.headerTab}>
            <div className={styles.statusLogo}>
              <div style={{ position: "relative", marginLeft: "15px" }}>
                <GradientCircleProgressbar
                  percentage={1}
                  width={70}
                  // primaryColor={["rgba(255, 138, 0, 1)", "rgba(255, 138, 0, 1)"]}
                >
                  <img src={Status1} alt="logo" className={styles.step1Image} />
                </GradientCircleProgressbar>
              </div>
            </div>
            <div className={styles.headerText}>
              <div className={styles.stepsCount}>Step {`${stepsCount}`}/3</div>
              <div className={styles.stepsText}>{`${stepText}`}</div>
            </div>
          </div>
        </div>

        <div className={styles.smsBody}>
          <div className={styles.smsBodyDeclration}>
            <div className={styles.smsBodyText}>
              We’ll send an{" "}
              <span style={{ color: "var(--on-icon, rgba(246, 163, 12, 1))" }}>
                SMS
              </span>{" "}
              from your registered mobile number to verify your identity and
              register this device
            </div>

            <div
              className={styles.smsVerifyLottie}
              ref={smsVerifyStepsLottieRef}
            >
              <LottieView
                animationData={smsVerifyStepsLottie}
                loop={true}
                className={styles.smsLottie}
              />
            </div>
          </div>

          <hr className={styles.horizontalLine2}></hr>

          <div className={styles.smsBody2}>
            <div className={styles.smsInstructionText}>Make sure that...</div>
            <div className={styles.smsBody2Text}>
              <div>
                <img src={SimCard} alt="" />
                <div style={{ marginLeft: "10px" }}>
                  SIM of your registered mobile number is in this device
                </div>
              </div>
              <div>
                <img src={ChatTearDrop} alt="" />
                <div style={{ marginLeft: "10px" }}>
                  Send the SMS via your registered number
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.infoTextToRedirectBack2}>
          Once done, return to this page for next steps.
        </div>

        <div className={styles.confirmBtn1Container2}>
          <Button2
            text1="continue to send SMS"
            text1Style={{ fontWeight: "bold" }}
            customClassName={styles.confirmBtn1}
            onClick={handleSendSmsClick}
          />
        </div>

        <BottomsheetComponent
          isBottomSheetOpen={isSmsStatusBottomsheet}
          setIsBottomSheetOpen={setIsSmsStatusBottomsheet}
          render={SmsStatusBottomSheet()}
        />

        <BottomsheetComponent
          isBottomSheetOpen={isSmsVerifiedBottomsheet}
          setIsBottomSheetOpen={setIsSmsVerifiedBottomsheet}
          render={SmsVerifiedBottomsheet()}
        />

        <BottomsheetComponent
          isBottomSheetOpen={isRedirectInfoBottomsheetOpen}
          setIsBottomSheetOpen={setIsRedirectInfoBottomsheetOpen}
          render={RedirectBackInfoBottomsheet()}
        />
      </>
    );
  }
};

export default SmsVerify;
