export interface GetCustomerResponseAddress {
  line1: string;
  line2: string | null;
  line3: string | null;
  city: string;
  pincode: string;
  state: string;
  country: string;
  landmark: string | null;
  countryCodeIso: string;
}

export interface GetCustomerResponse {
  id: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
  pancard: string;
  emailAddress: string;
  mobileNumber: string;
  mobileCountryCode: string;
  gender: string;
  dateOfBirth: string;
  currentAddress: GetCustomerResponseAddress;
  permanentAddress: GetCustomerResponseAddress;
  kycStatus: string;
  fkycStatus: string | null;
  lastFour: string;
}

export enum AccountStatus {
  ACTIVE = "ACTIVE",
  DORMANT = "DORMANT",
  CHARGE_OFF = "CHARGE_OFF",
  CLOSED = "CLOSED"
}

export interface GetAccountResponse {
  id: string;
  blockCodes: string[];
  type: string;
  currentBalance: number;
  approvedCreditLimit: number;
  availableCreditLimit: number;
  approvedCashLimit: number;
  availableCashLimit: number;
  currency: string;
  customerId: string;
  programId: string;
  rewardsSummary: {
    accrued: string | null;
    balance: string | number | null;
    redeemed: string | null;
    lapsed: string | null;
  };
  currentCycleStartDate: string;
  currentCycleEndDate: string;
  status: AccountStatus;
  dateCreated: string;
}

export interface GetCardResponse {
  id: string;
  accountId: string;
  blockCodes: string[];
  customerId: string;
  cardDisplayName: string;
  maskedCardNumber: string;
  cardExpiry: string;
  programId: string;
  physicallyIssued: boolean;
  virtuallyIssued: boolean;
  isPrimary: boolean;
  isActivated: boolean;
  isHotlisted: boolean;
  isLocked: boolean;
  cardStatus: string;
  isPhysicalCardActivated: boolean;
  isVirtualCardActivated: boolean;
  cardType: string;
  isVariantUpgradeActive: boolean;
  primaryCustomerId: string;
  scheme: string;
  journey: Journey;
  deliveredDate: string;
  activationDays: string;
  welcomeDays: string;
  cardCreatedOn: string;
}

export enum Journey {
  NEW_ACTIVATION = "NEW_ACTIVATION",
  OLD_ACTIVATION = "OLD_ACTIVATION"
}
export interface GetCardsResponse {
  id: string;
  accountId: string;
  customerId: string;
  cardDisplayName: string;
  maskedCardNumber: string;
  programId: string;
  physicallyIssued: boolean | null; // Change 'any' to the appropriate type if possible
  virtuallyIssued: boolean | null;
  isPrimary: boolean;
  isActivated: boolean;
  isHotlisted: boolean;
  isLocked: boolean;
  cardStatus: string;
  isPhysicalCardActivated: boolean;
  isVirtualCardActivated: boolean;
  cardType: string;
  scheme: string;
  blockCodes: string[]; // Change 'string' to the appropriate type if necessary
  isVariantUpgradeActive: boolean;
  primaryCustomerId: string;
  failure?: boolean;
  journey: Journey;
  deliveredDate: string;
  activationDays: string;
  welcomeDays: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GetDesignConfigResponse {}

export interface GetConfigDataRequest {
  programId: string;
}

export interface GetAuthConfigRequest {
  mpinMetaData: {
    customerId: string;
  };
  apiTags: Array<string>;
}

export interface GenerateSessionRequest {
  programId: string;
  customerId: string;
  accountId: string;
  cardId: string;
}

export enum AuthChallenge {
  OTP_SMS = "OTP_SMS",
  OTP_EMAIL = "OTP_EMAIL",
  MPIN = "MPIN",
  VERIFY_CUSTOMER = "VERIFY_CUSTOMER"
}

export enum AuthScope {
  FIRST_FACTOR = "FIRST_FACTOR",
  SECOND_FACTOR = "SECOND_FACTOR",
  STEP_UP = "STEP_UP"
}

export interface Challenge {
  scope: AuthScope;
  challenge: AuthChallenge;
}

export interface Flow {
  flowId: string;
  deviceTokenPresent?: boolean;
  mpinKnown?: boolean;
  challenges: Array<Challenge>;
}

export interface GetAuthConfigResponse {
  isMpinEverSet: { result: boolean };
  isMpinSet: { result: boolean };
  mpinTnCAccepted: { result: boolean };
  [key: string]: {
    result: boolean;
    challenges?: Array<Challenge>;
    conditional?: boolean;
    dynamicChallenges?: Array<Flow>;
  };
}

export enum EVENT_NAME {
  HOME_PAGE_VIEW = "Home Page View",
  PAGE_VIEW = "Page View",
  LOGIN_SUCCESS = "Login Success",
  LOGIN_SUCCESS_OSTOEMI_DEEPLINK = "OSTOEMI Login success (via deeplink)",
  LOGIN_SUCCESS_TXNTOEMI_DEEPLINK = "TXNTOEMI Login success (via deeplink)",
  NUDGE_CLICK = "Nudge Click",
  OTP_GENERATE = "Otp Generate",
  OTP_GENERATE_OSTOEMI_DEEPLINK = "OSTOEMI Login OTP Generate",
  OTP_GENERATE_TXNTOEMI_DEEPLINK = "TXNTOEMI Login OTP Generate",
  LOGIN_FAILURE = "Login Failure",
  LOGIN_FAILURE_OSTOEMI_DEEPLINK = "OSTOEMI Login failure (via deeplink)",
  LOGIN_FAILURE_TXNTOEMI_DEEPLINK = "TCN Login failure (via deeplink)",
  RESEND_OTP = "Resend Otp",
  RESEND_OTP_OSTOEMI_DEEPLINK = "OSTOEMI Login OTP Resend",
  RESEND_OTP_TXNTOEMI_DEEPLINK = "TXNTOEMI Login OTP Resend",
  OTP_VERIFY = "Otp Verify",
  OTP_VERIFY_OSTOEMI_DEEPLINK = "OSTOEMI Login OTP Verify",
  OTP_VERIFY_TXNTOEMI_DEEPLINK = "TXNTOEMI Login OTP Verify",
  GENERATE_CHALLENGE_SMS_OTP = "Generate Challenge Sms Otp",
  RESEND_CHALLENGE_SMS_OTP = "Resend Challenge Sms Otp",
  VERIFY_CHALLENGE_SMS_OTP = "Verify Challenge Sms Otp",
  APP_INSTALL_PROMPT = "App Install Prompt ",
  REWARDS_POINT_CLICK = "Rewards Point Click",
  ALL_NUDGES_CLICK = "All Nudges Click",
  ALL_CAMPAIGN_OFFERS_CLICK = "All Capmpaign Offers Click",
  ALL_CVP_OFFERS_CLICK = "All CVP Offers Click",
  ALL_OFFERS_CLICK = "All Offers Click",
  CARD_PAGE_CLICK = "Card Page Click",
  VIEW_VIRTUAL_CARD_CLICK = "View Virtual Card Click",
  VIEW_STATEMENT_CLICK = "View Statement Click",
  VIEW_MANAGE_CARD_CLICK = "View Manage Card Click",
  SEE_ALL_TRANSACTIONS_CLICK = "See All Transactions Click",
  MERCHANT_OFFER_CLICK = "Merchant Offer Click",
  CAMPAIGN_OFFER_CLICK = "Campaign Offer Click",
  CVP_OFFER_CLICK = "CVP Offer Click",
  OTHER_CARD_VIEW_CLICK = "Other Card View Click",
  SWITCH_TO_OTHER_CARD_CLICK = "Switch To Other Card Click",
  OSTOEMI_NOTIFICATION_TO_AMOUNT_SELECTOR_CLICK = "OSTOEMI notification click on cards homepage",
  OSTOEMI_SMS_DEEP_LINK_TO_AMOUNT_SELECTOR = "OSTOEMI SMS deep link redirection to amount selector",
  OSTOEMI_EMAIL_DEEP_LINK_TO_AMOUNT_SELECTOR = "OSTOEMI EMAIL deep link redirection to amount selector",
  VIEW_OSTOEMI_SELECT_AMOUNT_PAGE = "View OSTOEMI select amount page",
  OSTOEMI_AMOUNT_PAGE_CONVERT_NOW_CLICK = "Click convert now on OSTOEMI select amount page",
  VIEW_OSTOEMI_SELECT_TENURE_PAGE = "View OSTOEMI select tenure page",
  OSTOEMI_SELECT_TENURE_PAGE_CONFIRM_BUTTON_CLICK = "Click confirm on OSTOEMI select tenure page",
  VIEW_OSTOEMI_OVERVIEW_PAGE = "View OSTOEMI overview page",
  OSTOEMI_REPAYMENT_SHEDULE_CLICK = "Click OSTOEMI repayment schedule on overview page",
  VIEW_OSTOEMI_REPAYMENT_SCHEDULE_BOTTOM_SHEET = "View OSTOEMI repayment schedule pop-up on overview page",
  OSTOEMI_OVERVIEW_PAGE_VERIFY_OTP_CLICK = `Click “Verify OTP" on OSTOEMI overview page`,
  GENERATE_CHALLENGE_SMS_OTP_OSTOEMI = "Generate OTP for OSTOEMI request",
  VERIFY_CHALLENGE_SMS_OTP_OSTOEMI = "verify OTP for OSTOEMI request",
  OSTOEMI_REQUEST_PLACED_SUCCESSFULLY = "OSTOEMI request placed successfully",
  OSTOEMI_REQUEST_PLACED_FAILURE = "OSTOEMI request placed failure",
  VIEW_OSTOEMI_REQUEST_PLACED_BOTTOM_SHEET = "View OSTOEMI request placed screen",
  OSTOEMI_CONVERSION_IN_PROCESS_NOTIFICATION_CLICK = "Click OSTOEMI conversion in process notification on cards homepage",
  TXNTOEMI_SMS_DEEP_LINK_TO_ALL_TRANSACTIONS = "TXNTOEMI SMS deep link redirection to all transations",
  TXNTOEMI_EMAIL_DEEP_LINK_TO_ALL_TRANSATIONS = "TXNTOEMI EMAIL deep link redirection to all transactions",
  TXNTOEMI_SELECT_TRANSACTION_PAGE_CONVERT_NOW_CLICK = `"Click convert now" on TXNTOEMI select Transaction page`,
  TXNTOEMI_CONVERT_TO_EMI_UNBILLED_TRANSACTIONS_PAGE_CLICK = `"Convert to EMI" on View unbilled Transactions screen`,
  TXNTOEMI_CONVERT_TO_EMI_BILLED_TRANSACTIONS_PAGE_CLICK = `"Convert to EMI" on View billed Transactions screen`,
  TXNTOEMI_CONVERT_TO_EMI_SPEND_GROUP_TRANSACTIONS_PAGE_CLICK = `"Convert to EMI" on View spend group Transactions screen`,
  TXNTOEMI_CONVERT_TO_EMI_CARD_SUMMARY_PAGE_CLICK = `“Convert to EMI” on Card Summary page screen`,
  VIEW_TXNTOEMI_SELECT_TENURE_PAGE = "View TXNTOEMI select tenure page",
  VIEW_TXNTOEMI_SELECT_TRANSACTION_PAGE = "View TXNTOEMI select transaction page",
  TXNTOEMI_SELECT_TENURE_PAGE_PROCEED_BUTTON_CLICK = `Click proceed on TXNTOEMI select tenure page`,
  VIEW_TXNTOEMI_OVERVIEW_PAGE = "View TXNTOEMI overview page",
  TXNTOEMI_OVERVIEW_PAGE_VERIFY_OTP_CLICK = `Click "Verify OTP" on TXNTOEMI overview page`,
  GENERATE_CHALLENGE_SMS_OTP_TXNTOEMI = "Generate OTP for TXNTOEMI request",
  VERIFY_CHALLENGE_SMS_OTP_TXNTOEMI = "verify OTP for TXNTOEMI request",
  TXNTOEMI_REQUEST_PLACED_SUCCESSFULLY = "TXNTOEMI conversion success",
  TXNTOEMI_REQUEST_PLACED_FAILURE = "TXNTOEMI conversion failure",

  SPEND_ANALYTICS_CLICK = `Spend Analytics Click`,
  SPEND_ANALYTICS_PAGE_VIEW = `Spend Analytics Page View`,
  DEVICE_REGISTERED = `Device Registered`,
  DEVICE_DEREGISTERED = `Device De-registered`,
  SMS_VERIFICATION_RETRY_CLICK = `SMS Verification Retry Click`,
  CONTACT_SUPPORT_BY_MAIL = `Contact Support by Mail`,
  CONTACT_SUPPORT_BY_PHONE = `Contact Support By Phone`,
  ALL_MERCHANT_OFFERS_CLICK = `All Merchant Offers Click `,
  SPEND_ANALYTICS_GROUP_CLICK =  `Spend Analytics Group Click`,
  CARD_CONTROLS_CHANGED = `Card Controls Changed`,
  STATEMENT_PAGE_VIEW = `Statement Page View`

}

export interface EventCaptureRequest {
  eventName: EVENT_NAME;
  hfAccountId: string;
  hfCardId: string;
  hfCustomerId: string;
  primarySource: string;
  eventData?: {
    details?: string;
    nudgeId?: string;
    nudge_title?: string;
    nudge_position?: number;
    nudge_redirection_url?: string;
    mobileNumber?: string;
    cardNumber?: string;
    otpGeneratedStatus?: boolean;
    responseMessage?: string;
    loginFailureReason?: string;
    resendOtpCount?: number;
    lagTimeForInstallPrompt?: number;
    date?: string;
    eventType?: string;
    loginMode?: string;
    merchantOfferId?: string;
    campaignOfferId?: string;
    cvpOfferId?: string;
    cardId?: string;
    redirectionLink?: string;
    uniqueURL?: string;
  };
  deviceData: {
    device_os: string;
    os_version: string;
    browser: string;
    browser_version: string;
    geolocation: string;
    request_ip: string;
  };
  sessionMetaData: {
    referrer?: string;
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
  };
}
