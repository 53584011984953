import dayjs from "dayjs";
import {
  type GetApiConfigParams,
  type GetApiConfigReturnType
} from "./functionTypes";
import {
  Statement,
  type Transaction
} from "../apis/accountsApi/accountsApiTypes";
import {
  COOKIE_EXPIRATION_TIME_DEEP_LINK,
  MILESTONE_TEXT_DECIMAL_PRECISION
} from "./constants";
import AppApi from "../apis/appApi/AppApi";
import store from "../app/store";
import { EVENT_NAME } from "../apis/appApi/appApiTypes";
import { CookieType } from "./enums";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { smsErroCardBlockTime } from "./internalFlags";
import "dayjs/locale/en";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);

interface eventType {
  // update interface in both apiTypes and here for adding fields
  eventName: EVENT_NAME;
  eventData?: {
    details?: string;
    nudgeId?: string;
    nudge_title?: string;
    nudge_position?: number;
    nudge_redirection_url?: string;
    mobileNumber?: string;
    cardNumber?: string;
    otpGeneratedStatus?: boolean;
    responseMessage?: string;
    loginFailureReason?: string;
    resendOtpCount?: number;
    lagTimeForInstallPrompt?: number;
    date?: string;
    eventType?: string;
    loginMode?: string;
    merchantOfferId?: string;
    campaignOfferId?: string;
    cvpOfferId?: string;
    cardId?: string;
    redirectionLink?: string;
    requestSource?: string;
    uniqueURL?:string;
    billingCycle?:string;
    spendAnalyticsGroupId?:string;
    totalSpend?:number;
    groupName?:string;
    groupSpent?:number;
    numberOfTransactions?:number;
  };
}

export const getStatementDueDate = (dueDate: string) => {
  let date = dueDate;
  const dateFormat = "DD MMM' YY";
  return dayjs(date).format(dateFormat);
};

export const getTransactionDate = (
  transactionDate?: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  postingDate?: string
) => {
  let date = transactionDate;
  const givenDate = dayjs(date).format("YYYY-MM-DD");
  const todayDate = dayjs().format("YYYY-MM-DD");
  const dateFormat = "DD MMM YYYY";

  // if not today
  if (givenDate !== todayDate) {
    return dayjs(date).format(dateFormat);
    // + ", " + dayjs(date).format("h:mm A");
  }
  // return "Today, " + dayjs(date).format("h:mm A");
  return "Today";
};

export const getTransactionDate1 = (
  transactionDate?: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  postingDate?: string
) => {
  let date = transactionDate;
  const givenDate = dayjs(date).format("YYYY-MM-DD");
  const todayDate = dayjs().format("YYYY-MM-DD");
  const dateFormat = "MMM DD, YYYY";

  // if not today
  if (givenDate !== todayDate) {
    return dayjs(date).format(dateFormat);
    // return (
    //   dayjs(date).format(dateFormat) + " - " + dayjs(date).format("h:mm A")
    // );
  }
  return "Today";
  // return "Today, " + dayjs(date).format("h:mm A");
};

export const capitalizeFirsLetter = (sentence: string = "") => {
  if (sentence === "eazydiner") {
    return "EazyDiner";
  } else if (sentence === "Your eazydiner Benefits") {
    return "Your EazyDiner Benefits";
  } else {
    return sentence
      .split(" ")
      .map((word) => {
        word = word?.toLocaleLowerCase();
        return word && word.length > 1
          ? word[0].toUpperCase() + word.substring(1)
          : word?.toUpperCase();
      })
      .join(" ");
  }
};

export const truncateString = (string = "", maxLength = 50) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

export const formatNegativeAmount = (amount: any) => {
  let s = amount;
  if (s.includes("-")) {
    s = s.replace("-", "");
  }
  return s;
};

export const formatAmount = (amount: number) => {
  const formattedAmount = Math.abs(amount).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  });
  if (amount < 0) {
    return "- " + formattedAmount;
  }
  return formattedAmount;
};

export const formatAmount1 = (amount: number) => {
  const formattedAmount = Math.abs(amount).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  if (amount < 0) {
    return "- " + formattedAmount;
  }
  return formattedAmount;
};

export const formatAmount2 = (amount: number) => {
  const formattedAmount = Math.abs(amount).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  if (amount < 0) {
    return "- " + formattedAmount;
  }
  const formattedAmountWithSpace = formattedAmount.replace("₹", "₹ ");
  return formattedAmountWithSpace;
};

export const formatAmount3 = (amount: number) => {
  const formattedAmount = Math.abs(amount).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR"
  });
  if (amount < 0) {
    return "- " + formattedAmount;
  }
  const formattedAmoutWithSpace = formattedAmount.replace("₹", "₹ ");
  return formattedAmoutWithSpace;
};

// formatAmount4
// input1: 54321.55
// output1: ₹54321.55
// input2: 123.00
// output2: ₹123
export const formatAmount4 = (amount: number) => {
  const formattedAmount = amount.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: amount % 1 === 0 ? 0 : 2,
    maximumFractionDigits: 2
  });
  if (amount < 0) {
    return "- " + formattedAmount;
  }
  return formattedAmount;
};
export const formatAmount5 = (amount: number) => {
  const formattedAmount = Math.abs(amount).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });
  if (amount < 0) {
    return "- " + formattedAmount;
  }
  return formattedAmount;
};

export const getMaskedMobileNumber = (n: string = ""): string =>
  n.replace(/\d(?=\d{2})/g, "*");

export const getMaskedMobileNumberForLastTwo = (n: string = ""): string =>
  `********${n.slice(-3)}`;

export const getMaskedMobileNumberForLogs = (n: string = ""): string =>
  n.replace(/\d(?=\d{2})/g, "*");

export const getApiConfig = ({
  apiToken
}: GetApiConfigParams): GetApiConfigReturnType => {
  const headers: any = {};

  if (apiToken) headers.Authorization = apiToken;

  return { headers };
};

export const formatCardNumber = (num: string): string =>
  num.match(/.{1,4}/g)?.join(" ") || "";

export function fallbackCopyTextToClipboard(text: string) {
  let textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.readOnly = true;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    let successful = document.execCommand("copy");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let msg = successful ? "successful" : "unsuccessful";
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}

export const textEllipsis = (text: string) =>
  (text ?? "").length > 15 ? text.slice(0, 3) + "....." + text.slice(-3) : text;

export const handleOfferRedirectionLinkClick = (
  event: React.MouseEvent<HTMLElement>,
  redirectionLink: string
) => {
  event.stopPropagation();
  window.open(redirectionLink, "_blank");
};

export const getSortedTransactions = (transactions: Transaction[]) => [
  ...transactions.sort((txn1, txn2) =>
    dayjs(txn2.transactionDate).diff(dayjs(txn1.transactionDate), "second")
  )
];

export const formatControlCharactersInJson = (text = "") =>
  text.replace(/\t/g, "\u00A0");

export const parseUnicodeInString = (text: string) => {
  // use a regular expression to find and replace unicodes
  const unicodeRegex = /U\+([0-9A-F]{4,6})/g;

  const replacedText = text.replace(unicodeRegex, (match, hexValue) => {
    const unicodeCharacter = String.fromCodePoint(parseInt(hexValue, 16));
    return unicodeCharacter;
  });

  return replacedText;
};

export const autoReadOtp = (callback: (otp: string) => void) => {
  try {
    if ((window as any).OTPCredential && navigator.credentials) {
      const ac = new AbortController();

      navigator.credentials
        .get({ otp: { transport: ["sms"] }, signal: ac.signal })
        .then((otp) => {
          if (otp && otp.code) {
            callback(otp.code);
          }
        })
        .catch((e: any) => {
          console.error("An exception occurred while auto reading OTP", e);
        });
    }
  } catch (error) {
    console.error(
      "An exception occurred while initiating auto reading of OTP",
      error
    );
  }
};

export const focusOnInput = (containerId: string, inputIndex: number) => {
  setTimeout(() => {
    const divElement = document.getElementById(containerId);
    if (divElement) {
      const inputElements = divElement.querySelectorAll("input");
      if (inputElements.length >= inputIndex + 1) {
        const element = inputElements[inputIndex];
        element?.focus();
      }
    }
  }, 100);
};

export const truncateMilestoneTextDecimal = (value: number) => {
  const multiplier = Math.pow(10, MILESTONE_TEXT_DECIMAL_PRECISION);
  return Math.trunc(value * multiplier) / multiplier;
};

export const filterUniqueDates = (inputArray: Statement[]): Statement[] => {
  if (inputArray.length === 0) return [];
  const uniqueDates: any = {};

  inputArray.forEach((obj) => {
    const key = `${obj.fromDate}-${obj.toDate}`;

    if (key in uniqueDates) {
      const existingBalanceDue = uniqueDates[key].balanceAmountDue;
      const currentBalanceDue = obj.balanceAmountDue;

      if (currentBalanceDue > existingBalanceDue) {
        uniqueDates[key] = obj;
      }
    } else {
      uniqueDates[key] = obj;
    }
  });

  const resultArray: Statement[] = Object.values(uniqueDates);
  return resultArray;
};

export const getPWADisplayMode = () => {
  const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
  if (isStandalone) {
    return "app";
  }
  return "";
};

export const captureEvent = (data: eventType) => {
  const state = store.getState();

  AppApi.captureEvent({
    eventName: data.eventName,
    eventData: data.eventData || {},
    hfAccountId: state.user.selectedAccountId!,
    hfCardId: state.user.selectedCardId!,
    hfCustomerId: state.user.customerId!,
    primarySource: window.location.href,
    sessionMetaData: {
      referrer: window.document.referrer
    }
  });
};

export const getDeviceIdCookie = () => {
  let cookieArray = document.cookie.split("; ");

  const deviceIDCookie = cookieArray.find((ele) =>
    ele.includes(CookieType.DEVICE_ID)
  );

  if (deviceIDCookie) {
    return deviceIDCookie.split("deviceId=")[1];
  }
};

// Function to create a new cookie
export const createCookie = (
  name: string,
  value: string,
  expiration: string
) => {
  document.cookie = `${name}=${value};expires=${expiration};path=/`;
};

// Function to convert timestamp to IST and format it for cookie expiry
export const convertToISTAndFormat = (timestamp: string) => {
  // const istTime = dayjs(timestamp).tz("Asia/Kolkata"); // Assuming IST timezone
  // console.log(istTime);
  // const formattedExpiry = istTime.format("ddd, DD MMM YYYY HH:mm:ss [GMT]");
  // return formattedExpiry;
  return dayjs(timestamp).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
};

export const IST_Time = (timestamp: string) => {
  const istTime = dayjs(timestamp).tz("Asia/Kolkata"); // Assuming IST timezone
  const formattedExpiry = istTime.format("ddd, DD MMM YYYY HH:mm:ss IST");
  return formattedExpiry;
};

export const isCardUnblockedCookie = (timer: string) => {
  return dayjs().isSameOrBefore(dayjs(timer));
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (let cookie of cookies) {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};

export const cookieCheck = (uniqueUrl: string = "") => {
  let cookieArray = document.cookie.split("; ");
  let isDeviceIdPresent = false;
  let isUniqueUrlPresent = false;
  let isProceedToLogin = false;
  let uniqueUrlFromCookie;

  const deviceIDCookie = cookieArray.find((ele) =>
    ele.includes(CookieType.DEVICE_ID)
  );

  const uniqueUrlCookie = cookieArray.find((ele) =>
    ele.includes(CookieType.UNIQUE_URL)
  );

  if (deviceIDCookie) {
    isDeviceIdPresent = true;
  }

  if (uniqueUrlCookie) {
    isUniqueUrlPresent = true;
  }

  if (uniqueUrl && uniqueUrlCookie && isDeviceIdPresent) {
    uniqueUrlFromCookie = uniqueUrlCookie.split("uniqueURL=")[1];
    if (uniqueUrlFromCookie === uniqueUrl) {
      isProceedToLogin = true;
    }
  }

  if (
    getPWADisplayMode() === "app" &&
    (isUniqueUrlPresent || isDeviceIdPresent)
  ) {
    isProceedToLogin = true;
  }
  if (!isProceedToLogin) {
    if (!isDeviceIdPresent) {
      deleteAllCookies();
    }
  }
  return isProceedToLogin;
};

export const getUniqueURLFromCookie = () => {
  let cookieArray = document.cookie.split("; ");
  const uniqueUrlFromCookie = cookieArray.find((ele) =>
    ele.includes(CookieType.UNIQUE_URL)
  );
  if (uniqueUrlFromCookie) {
    return uniqueUrlFromCookie.split("uniqueURL=")[1];
  }
};

export const cardBlockTimeForSmsRetry = () => {
  const futureDate = dayjs
    .utc()
    .add(smsErroCardBlockTime, "hour")
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DDTHH:mm:ss");
  return futureDate;
};

export const formatDate = (date: string) => {
  if (!date) {
    return;
  }
  const parsedDate: Date | undefined = new Date(date);
  const dateOptions: Intl.DateTimeFormatOptions = {
    day: "2-digit"
  };

  const monthOptions: Intl.DateTimeFormatOptions = {
    month: "short"
  };

  const yearOptions: Intl.DateTimeFormatOptions = {
    year: "2-digit"
  };

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true
  };

  const amPm: string = parsedDate.getHours() >= 12 ? "PM" : "AM";

  const day = new Intl.DateTimeFormat("en-IN", dateOptions).format(parsedDate);
  const month = new Intl.DateTimeFormat("en-IN", monthOptions).format(
    parsedDate
  );
  const year = new Intl.DateTimeFormat("en-IN", yearOptions).format(parsedDate);
  const time = new Intl.DateTimeFormat("en-IN", timeOptions).format(parsedDate);

  const formattedDateTime = `${day} ${month} '${year}, ${time.substring(
    0,
    5
  )} ${amPm}`;

  return formattedDateTime;
};

export const cleanBase64String = (input: string) => {
  // Use a regular expression to trim leading spaces after "//" but keep spaces between base64 strings
  return input.replace(/\/\/\s*/, "//");
};

export const createDeepLinkCookie = (value: string) => {
  const expiryInMilliseconds = Date.now() + COOKIE_EXPIRATION_TIME_DEEP_LINK;
  const expirationTime = dayjs()
    .add(COOKIE_EXPIRATION_TIME_DEEP_LINK, "milliseconds")
    .format("ddd, DD MMM YYYY HH:mm:ss [GMT]");
  document.cookie = `${CookieType.DEEPLINK_ROUTE}=${value}~${expiryInMilliseconds}; expires=${expirationTime}; path=/; SameSite=None; Secure`;
};
export const getDeepLinkCookieValue = () => {
  let cookieArray = document.cookie.split("; ");
  const cookie = cookieArray.find((ele) =>
    ele.includes(CookieType.DEEPLINK_ROUTE)
  );
  if (cookie) {
    const expirationTime = +cookie.split("=")[1].split("~")[1];
    if (expirationTime < Date.now()) {
      deleteDeepLinkCookie();
      return "";
    } else {
      return cookie.split("=")[1].split("~")[0];
    }
  }
};
export const deleteDeepLinkCookie = () => {
  document.cookie = `${CookieType.DEEPLINK_ROUTE}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
};

export const isReload = () => {
  let isReload = false;
  const entries = performance?.getEntriesByType("navigation");
  entries.forEach((entry) => {
    if (entry.entryType === "navigation") {
      if (entry?.toJSON()?.type === "reload") {
        isReload = true;
      }
    }
  });
  if (performance?.navigation?.type === performance?.navigation?.TYPE_RELOAD) {
    isReload = true;
  }
  return isReload;
};

export const getFormattedTimestamp = (time: string) => {
  const parsedTime = dayjs(time, "YYYY-MM-DD HH:mm:ss"); // Adjust the format string as needed

  // Format the parsed time
  const formattedTime = parsedTime.format("h:mm A");

  const currentTime = dayjs();
  const diffInSeconds = currentTime.diff(parsedTime, "second");

  const days = Math.floor(diffInSeconds / (60 * 60 * 24));
  const remainingSeconds = diffInSeconds % (60 * 60 * 24);
  const hours = Math.floor(remainingSeconds / (60 * 60));
  const minutes = Math.round((remainingSeconds / 60) % 60);

  if (dayjs(parsedTime).isToday()) {
    return `Today, ${formattedTime}`;
  } else {
    if (days === 0 && hours === 0) {
      // Less than a day, show hours and minutes
      return `${minutes} minutes ago`;
    } else if (days === 0) {
      // Exactly 24 hours, show hours
      return `${hours} hour(s) ago`;
    } else {
      // More than 24 hours, show days
      return `${days} day(s) ago`;
    }
  }
};