/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from "react";
import styles from "./SpendAnalytics.module.scss";
import { Button2, Header, Selector, ToastType } from "../../components";
import DoughnutChart from "../../components/doughnutChart/DoughnutChart";
import { ApiResponse, SpendGroup } from "../../utils/SpendAnalyticsUtil";
import cactus from "../../assets/svgs/cactus.svg";
import arrowDown from "../../assets/svgs/arrow-down-white.svg";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedAccount } from "../../features/user/userSlice";
import dayjs from "dayjs";
import {
  API_REQUEST_DATE_FORMAT,
  CORRELATION_ID_HEADER
} from "../../utils/constants";
import { authenticateApi } from "../../auth/auth";
import ApiIds from "../../auth/ApiIds";
import { AuthStatus } from "../../features/auth/authSliceTypes";
import logger from "../../utils/logger";
import { toast } from "../../components/toast/toast";
import { useLocation, useNavigate } from "react-router-dom";
import AccountsApi from "../../apis/accountsApi/AccountsApi";
import Loader from "../../components/loader/loader";
import { RootState } from "../../app/store";
import { setMiscellaneousState } from "../../features/miscellaneous/miscellaneousSlice";
import { captureEvent } from "../../utils/functions";
import { EVENT_NAME } from "../../apis/appApi/appApiTypes";

export interface BillingCycle {
  from: string;
  to: string;
}

const SpendAnalytics = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const account = useSelector(getSelectedAccount)!;
  const spendAnalyticsBillingCycle = useSelector(
    (state: RootState) => state.miscellaneous.spendAnalyticsBillingCycle
  )!;
  const billingCycles = useSelector(
    (state: RootState) => state.miscellaneous.billingCycles
  )!;

  //Api response for spend Groups
  const [data, setData] = useState<ApiResponse | null>();
  //Selected billing cycle
  const [selectedBillingCycle, setSelectedBillingCycle] =
    useState<BillingCycle>(location?.state?.billingCycle || {});
  // All billings cycles
  const [analyticsBillingCycles, setAnalyticsBillingCycles] = useState<
    BillingCycle[]
  >([]);
  //To open select bottom sheet
  const [isCycleSelectOpen, setIsCycleSelectOpen] = useState<boolean>(false);
  //To get data from Api
  const [offset, setOffset] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  //Total spend to show zero state screen
  let totalSpend: number = 0;

  const parsedDate = dayjs(billingCycles[0]?.to).add(1, "day");

  // Format the updated date as needed (e.g., with day and time)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const formattedDate = parsedDate.format("YYYY-MM-DDTHH:mm:ssZ");

  //will compare billing cycle from current cycle start and show Unbilled in calendar and bottom sheet
  const isCurrentBillingCycleSelected: boolean =
    dayjs().format("YYYY-MM-DD") ===
    dayjs(selectedBillingCycle.to).format("YYYY-MM-DD");

  //Labels for select sheet
  const renderBillingCycleSelectorLabel = (
    billingCycle: BillingCycle,
    index: number
  ) =>
    index === 0
      ? "Unbilled Transactions"
      : `${dayjs(billingCycle.from).format("DD MMM 'YY")} - ${dayjs(
          billingCycle.to
        ).format("DD MMM 'YY")}`;

  //set selected billing cycle to clicked cycle
  const onBillingCycleSelect = (billingCycle: BillingCycle, index: number) => {
    setOffset(-index);
    setSelectedBillingCycle(billingCycle);
    setIsCycleSelectOpen(false);
    dispatch(
      setMiscellaneousState({
        selectedBillingCycle: index !== 0 ? billingCycle : null
      })
    );
    if (index === 0) {
      dispatch(
        setMiscellaneousState({
          statementBillingCycle: billingCycles[1]
        })
      );
    }
  };

  //open and close select bottom sheet
  const openCycleSelection = () => {
    setIsCycleSelectOpen(true);
  };

  const closeCycleSelection = () => {
    setIsCycleSelectOpen(false);
  };

  //Go to merchant offers
  const handleGoToOffers = () => {
    navigate("/merchantOffers");
    captureEvent({ eventName: EVENT_NAME.ALL_MERCHANT_OFFERS_CLICK });
  };

  // Fetch spend groups data from api
  const getSpendGroups = async () => {
    // if (!isCurrentBillingCycleSelected && offset === 0) return;
    setIsLoading(true);

    try {
      const authResult = await authenticateApi({
        apiId: ApiIds.FETCH_TRANSACTIONS,
        actionText: "to see spend analytics"
      });

      if (authResult.status === AuthStatus.FAILURE) {
        logger.error({
          accountId: account.id,
          error: authResult.error,
          message: `An error occurred while authenticating get spend groups`
        });
        toast(ToastType.ERROR, authResult.message);
        navigate(-1);
        return;
      } else if (authResult.status === AuthStatus.CANCELLED) {
        navigate(-1);
        return;
      }

      const response = await AccountsApi.getSpendGroups(
        account.id,
        authResult.apiToken,
        {
          period: {
            type: "RANGE",
            startDate: dayjs(selectedBillingCycle?.from).format(
              API_REQUEST_DATE_FORMAT
            ),
            endDate: dayjs(selectedBillingCycle?.to).format(
              API_REQUEST_DATE_FORMAT
            ),
            dateType: "POSTING_DATE"
          }
        }
      );

      if (response.status !== 200) {
        logger.error({
          correlationId: response.headers[CORRELATION_ID_HEADER],
          accountId: account.id,
          responseData: response.data,
          message: `An error occurred while getting spend groups`
        });
        toast(ToastType.ERROR, "An error occurred");
        navigate(-1);
      }
      if (response.data) {
        setData(response.data);
        dispatch(
          setMiscellaneousState({
            spendAnalyticsBillingCycle: {
              from: selectedBillingCycle.from,
              to: selectedBillingCycle.to
            }
          })
        );
      }
    } catch (error: any) {
      logger.error({
        message: `An exception occurred while getting spend groups`,
        error: error.message,
        accountId: account.id,
        stackTrace: error.stack
      });
      toast(
        ToastType.ERROR,
        "We are unable to process your request. Please try again later"
      );
      navigate(-1);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    dispatch(
      setMiscellaneousState({
        selectedBillingCycle: location?.state?.billingCycle
      })
    );
  }, []);

  useEffect(() => {
    captureEvent({
      eventName: EVENT_NAME.SPEND_ANALYTICS_PAGE_VIEW,
      eventData: { billingCycle: location?.state?.billingCycle }
    });
  }, [selectedBillingCycle]);

  //will create 6 billing cycles in given format for bottom sheet
  useEffect(() => {
    // const unbilledCycle = {
    //   from: dayjs(billingCycles[0]?.to)
    //     .add(1, "day")
    //     .format(API_REQUEST_DATE_FORMAT),
    //   to: dayjs().format(API_REQUEST_DATE_FORMAT)
    // };

    const cycles = [...billingCycles];

    setAnalyticsBillingCycles(cycles);
    if (!selectedBillingCycle.from) {
      setSelectedBillingCycle(cycles[0]);
    }
  }, [account.currentCycleStartDate]);

  //If selected Billing cycle is passed from statement page, it will change the Offset value and make api fetch call
  useEffect(() => {
    analyticsBillingCycles.forEach((el, index) => {
      if (
        el.from === selectedBillingCycle.from &&
        el.to === selectedBillingCycle.to
      ) {
        setOffset(-index);
      }
    });
  }, [location?.state?.from, analyticsBillingCycles]);

  //fetch spend groups from api on change in offset value
  useEffect(() => {
    getSpendGroups();
  }, [offset]);

  //count total spend value
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data?.spendGroups?.forEach((el: SpendGroup, ind: number) => {
    totalSpend += el.totalSpend;
  });

  useEffect(() => {
    if (spendAnalyticsBillingCycle?.from) {
      setSelectedBillingCycle(spendAnalyticsBillingCycle);
      dispatch(
        setMiscellaneousState({
          selectedBillingCycle: spendAnalyticsBillingCycle
        })
      );
    }
  }, []);

  return isLoading ? (
    <div className={styles.loader}>
      <Loader
        text1Style={{ fontWeight: "bold" }}
        text1="loading"
        text2="your Spend Analytics"
        noTextStyle
      />
    </div>
  ) : (
    <div className={styles.spendAnalysisWrapper}>
      <div className={styles.headerContainer}>
        <Header
          label="Spend Analytics"
          bgColor="rgba(20, 20, 20, 0.80)"
          customClassName={styles.header}
        />
        <div className={styles.calendar} onClick={openCycleSelection}>
          <div className={styles.calendarDate}>
            {isCurrentBillingCycleSelected
              ? "Unbilled"
              : `${dayjs(selectedBillingCycle.from).format("DD MMM")} - ${dayjs(
                  selectedBillingCycle.to
                ).format("DD MMM")}`}
          </div>
          <div className={styles.arrowIcon}>
            <img src={arrowDown} alt="" />
          </div>
        </div>
      </div>
      {/* Doughnut chart along with category container */}
      {data?.spendGroups &&
        data?.spendGroups?.length !== 0 &&
        totalSpend !== 0 && <DoughnutChart Data={data} />}
      {/* zero state page */}
      {(data?.spendGroups?.length === 0 || totalSpend === 0) && (
        <div className={styles.zeroState}>
          <div className={styles.zeroStateImage}>
            {" "}
            <img src={cactus} alt="cactus" />
          </div>
          <div className={styles.zeroStateTitle}>
            <span>no</span> transactions for this cycle
          </div>
          <div className={styles.zeroStateText}>
            Set your expenses free and watch as they dance into the spotlight of
            analysis – a symphony of spending savvy!
          </div>
          <Button2
            text1="go to"
            text2="offers"
            text1Style={{ fontWeight: "bold" }}
            customClassName={styles.zeroStateButton}
            onClick={handleGoToOffers}
          />
          <div className={styles.zeroStateBackground}></div>
        </div>
      )}
      <Selector<BillingCycle>
        isOpen={isCycleSelectOpen}
        title="Select a Billing Cycle"
        items={analyticsBillingCycles}
        renderLabel={renderBillingCycleSelectorLabel}
        isItemSelected={(billingCycle) =>
          billingCycle.from === selectedBillingCycle?.from
        }
        onSelect={onBillingCycleSelect}
        handleClose={closeCycleSelection}
        footer=""
      />
    </div>
  );
};

export default SpendAnalytics;
