/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import Header from "../../components/header/header";
import styles from "./customerSupportStyles.module.scss";
import call from "../../assets/svgs/call.svg";
import info from "../../assets/svgs/info.svg";
import sms from "../../assets/svgs/sms.svg";
import { getConfig } from "../../data/config";
import { Arrow } from "../../components";
import { CONTACT_MOBILE_NUMBER } from "../../utils/constants";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSelectedCard } from "../../features/user/userSlice";
import { captureEvent } from "../../utils/functions";
import { EVENT_NAME } from "../../apis/appApi/appApiTypes";

 function CustomerSupport() {

   const [config, setConfig] = useState<any>();
   const card = useSelector(getSelectedCard)!;
   useEffect(() => {
     getConfig().then(res => setConfig(res));
   }, [card]);


  const onMailClick = () => {
    window.open(`mailto:${config?.customerSupport.email.link}`, "_blank");
    captureEvent({eventName:EVENT_NAME.CONTACT_SUPPORT_BY_MAIL});
  };
  const onPhoneClick = (phone: string) => {
    window.open(`tel:${phone}`);
    captureEvent({eventName:EVENT_NAME.CONTACT_SUPPORT_BY_PHONE});
  };
  return (
    <div>
      <Header label="Customer Care" />
      <div className={styles.manageCustomerCare}>
        <div
          className={styles.customerCareContainer}
          onClick={() => onPhoneClick("18602677777")}
        >
          <div>
            <div className={styles.flexItemsBetween}>
              <div className={styles.flexItems} style={{ gap: "12px" }}>
                <div className={styles.customerCareIconContainer}>
                  <img
                    src={call}
                    alt="icon"
                    className={styles.customerCareIcon}
                  />
                </div>
                <div className={styles.customerCareTextContainer}>
                  <div className={styles.customerCareText1}>
                    Call us on these numbers (24x7)
                  </div>
                  <div
                    className={`${styles.customerCareText3}`}
                    style={{ marginTop: "6px" }}
                  >
                    <span onClick={() => onPhoneClick("18602677777")}>
                      {`${CONTACT_MOBILE_NUMBER}`}
                    </span>{" "}
                    <span className={styles.slash}> / </span>{" "}
                    <span onClick={() => onPhoneClick("02242207777")}>
                      {" "}
                      022 4220 7777
                    </span>{" "}
                  </div>
                </div>
                <div className={styles.arrowContainer}>
                  <Arrow className={styles.cta} />
                </div>
              </div>
            </div>
            <div className={styles.divider} />
            <div
              className={styles.flexItems}
              style={{ padding: "5px", alignItems: "start" }}
            >
              <img
                src={info}
                alt="icon"
                className={styles.customerCareIcon}
                style={{ paddingRight: "10px" }}
              />
              <div className={styles.customerCareText2}>
                Please call above numbers to report any fraudulent transactions
                or activity on your account
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${styles.customerCareORBlock} ${styles.flexItemsCenter}`}
        >
          <div className={styles["hr-container"]}></div>
          <div className={styles.customerCareORBlockText}> or</div>
          <div className={styles["hr-container"]} />
        </div>
        <div className={styles.customerCareContainer} onClick={onMailClick}>
          <div className={styles.customerCareIconContainer}>
            <img src={sms} alt="icon" className={styles.customerCareIcon} />
          </div>
          <div className={styles.customerCareTextContainer}>
            <div className={styles.customerCareText1}>
              {config?.customerSupport.email.text}
            </div>
            <div className={styles.customerCareText3}>
              {config?.customerSupport.email.link}
            </div>
          </div>
          <div className={styles.arrowContainer1}>
            <Arrow className={styles.cta} />
          </div>
          {/* <Button3 customClassName={styles.btn}/> */}
        </div>
        {/* <div className={styles.flexItemsCenter}>
          <div className={styles.gradientDivider}></div>
        </div> */}
      </div>
    </div>
  );
}

export default CustomerSupport;
